import { DrawerCustom } from "@components/modules/custom-drawer";
import { IProductRatingSummary } from "@interfaces/market/ratings.interface";
import { cn } from "@utils/tailwind-marge";
import { Button, Rate } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const getStarCount = (
    star: number,
    summaryInfo: IProductRatingSummary,
) => {
    const {
        fiveStarCount,
        fourStarCount,
        threeStarCount,
        twoStarCount,
        oneStarCount,
    } = summaryInfo;
    let total = 0;
    switch (star) {
        case 1:
            total = oneStarCount;
            return total;
        case 2:
            total = twoStarCount;
            return total;
        case 3:
            total = threeStarCount;
            return total;
        case 4:
            total = fourStarCount;
            return total;
        case 5:
            total = fiveStarCount;
            return total;
        default:
            return total;
    }
};

type SelectStarProps = {
    star?: number;
    onChange?: (star?: number) => void;
    isOpenSelectStarDrawer?: boolean;
    setIsOpenSelectStarDrawer: (value: boolean) => void;
    summaryInfo: IProductRatingSummary;
};

export const SelectStarDrawer = (props: SelectStarProps) => {
    const {
        isOpenSelectStarDrawer,
        onChange,
        setIsOpenSelectStarDrawer,
        star,
        summaryInfo,
    } = props;

    const { t } = useTranslation("common");

    const [selected, setSelected] = useState<number | undefined>(star);

    const onSelect = (value: number) => {
        setSelected(value);
    };

    const starItems = [];
    for (let i = 5; i >= 1; i--) {
        starItems.push(
            <StarItem
                star={i}
                total={getStarCount(i, summaryInfo)}
                active={selected === i}
                onSelect={onSelect}
            />,
        );
    }

    const onCancel = (event: any) => {
        event.stopPropagation();
        onChange?.();
    };
    const onSave = (event: any) => {
        event.stopPropagation();
        onChange?.(selected);
    };

    return (
        <DrawerCustom
            closable={true}
            onClose={(event) => {
                event.stopPropagation();
                setIsOpenSelectStarDrawer(false);
            }}
            open={isOpenSelectStarDrawer}
            placement="bottom"
            key="select-start-drawer"
            height={"fit-content"}
            className="rounded-tl-3xl rounded-tr-3xl"
            footer={
                <section className="w-full flex gap-2">
                    <Button
                        onClick={onCancel}
                        type="primary"
                        className={cn(
                            "h-10 rounded-none !bg-white flex justify-center items-center w-full",
                            "font-sans font-semibold text-base text-primary",
                        )}
                    >
                        <p className="!text-primary !hover:text-primary">
                            {t("rating.cancel")}
                        </p>
                    </Button>

                    <Button
                        onClick={onSave}
                        type="primary"
                        className={cn(
                            "h-10 rounded-none bg-primary flex justify-center items-center w-full",
                            "font-sans font-semibold text-base text-white",
                        )}
                    >
                        {t("rating.agree")}
                    </Button>
                </section>
            }
            closeIcon={
                <div
                    className={cn(
                        "w-16 h-2 bg-neutral-10 rounded-md absolute",
                        "left-1/2 translate-x-[-50%]",
                        "hover:bg-neutral-20 transition",
                    )}
                />
            }
        >
            <section className="flex flex-col gap-2 h-full justify-end mt-4">
                {starItems}
            </section>
        </DrawerCustom>
    );
};

type StarItemProps = {
    star: number;
    total?: number;
    active?: boolean;
    onSelect: (star: number) => void;
};
const StarItem = ({ star, total, active, onSelect }: StarItemProps) => {
    return (
        <div
            onClick={() => onSelect(star)}
            className={cn(
                "flex gap-2 px-3 py-2 border border-solid cursor-pointer transition-all",
                "hover:border-primary hover:text-primary hover:font-semibold",
                active && "border-primary text-primary font-semibold",
            )}
        >
            <Rate value={star} disabled />
            <label>{total}</label>
        </div>
    );
};
