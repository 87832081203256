import { CurrencyType } from "../enums/currency-type.enum";

export const GOOGLE_QR_CODE_URL = "https://chart.googleapis.com/chart?cht=qr";

export enum ServiceProviderEnum {
    DOMESTIC = "",
    VISA = "VISA",
    MOMO = "MOMO",
    ZALO = "ZALOPAY",
    QRPAY = "QRPAY",
    VNPAY = "VNPAY",
    VIETTEL = "VIETTEL",
    SKY = "SKY",
}

export const CONFIG_PAYMENT_SETTING: Record<string, string | any> = {
    bankCard: {
        label: "atm_card_viet_nam",
        method: "DOMESTIC",
        serviceProvider: "",
        image: "/images/select_domestic.png",
    },
    internationalPayCard: {
        label: "international_card",
        method: "INTERNATIONAL",
        serviceProvider: "",
        image: "/images/select_international.png",
    },
    ewalletMomo: {
        label: "ewallet_momo",
        method: "WALLET",
        serviceProvider: "MOMO",
        image: "/images/select_momo.png",
    },
    ewalletZalo: {
        label: "ewallet_zalo",
        method: "WALLET",
        serviceProvider: "ZALOPAY",
        image: "/images/select_zalopay.png",
    },
    ewalletViettelMoney: {
        label: "ewallet_viettel",
        method: "WALLET",
        serviceProvider: "VIETTEL",
        image: "/images/select_domestic.png",
    },
    ewalletSkypay: {
        label: "ewallet_sky",
        method: "WALLET",
        serviceProvider: "GALAXYPAY",
        image: "/images/select_domestic.png",
    },
    qrVietqr: {
        label: "qr_vietpay",
        method: "QRPAY",
        serviceProvider: "QRPAY",
        image: "/images/select_qr_pay.png",
    },
    qrVnpay: {
        label: "qr_vnpay",
        method: "QRPAY",
        serviceProvider: "VNPAY",
        image: "/images/select_qr_pay.png",
    },
};

export const GPAY_SERVICE_PROVIDER = [
    "bankCard",
    "internationalPayCard",
    "ewalletMomo",
    "ewalletZalo",
    "ewalletViettelMoney",
    "ewalletSkypay",
    "qrVnpay",
    "qrVietqr",
];

export const DEFAULT_CURRENCY_CONFIG = {
    id: "005447b0-8d4b-404a-86aa-9523336137e5",
    name: "Vietnamese Dong",
    code: CurrencyType.Vnd,
    config: {
        symbol: "đ",
        currencyFormat: "VND",
        dateFormat: "DD-MM-YYYY",
        localeString: "vi-VN",
    },
};

export const DEFAULT_LOCALE = "vi";
