import { useState } from "react";
import { ImagesSlick } from "../../../components/images-slick";
import { NProduct } from "../../../interfaces";
import { Block } from "../block";
import { ProductInfo } from "../product-info";
import { ProductAttributes } from "./product-attributes";

type Prop = {
    isEdit: boolean;
    product: NProduct.IProduct;
    defaultSizeId?: string;
    onChange: (attribute: NProduct.IProductAttribute | undefined) => void;
};

export const ConfigurableProduct = (props: Prop) => {
    const { isEdit, product, defaultSizeId, onChange } = props;

    const [defaultAttributeId, setDefaultAttributeId] = useState<
        string | undefined
    >(defaultSizeId);

    const onSelectImage = (id: string) => {
        setDefaultAttributeId(id);
    };

    const onSelectAttribute = (
        attribute: NProduct.IProductAttribute | undefined,
    ) => {
        if (attribute?.id) {
            onSelectImage(attribute.id);
        }
        onChange?.(attribute);
    };

    return (
        <>
            {isEdit ? (
                <Block className="mb-4 flex items-center gap-2">
                    <img alt="edit" src="/icons/edit.svg" className="w-6 h-6" />
                    <p className="text-lg text-neutral-60 font-semibold font-sans">
                        {product.name}
                    </p>
                </Block>
            ) : (
                <Block className="mb-4 divider">
                    <ProductInfo product={product} hidePhoto={true} />
                </Block>
            )}
            <Block>
                <ImagesSlick
                    images={product?.gallery || []}
                    defaultId={defaultAttributeId}
                    onChange={onSelectImage}
                />
            </Block>
            <Block className="mb-6">
                <ProductAttributes
                    attributes={product.productSizes}
                    onChange={onSelectAttribute}
                    defaultAttributeId={defaultAttributeId}
                />
            </Block>
        </>
    );
};
