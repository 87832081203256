export enum UnitEnum {
    days = "day",
    weeks = "week",
    months = "month",
    years = "year",
    hours = "hour",
    minutes = "minute",
    seconds = "second",
    milliseconds = "millisecond",
}
