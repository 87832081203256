import { ItemVisibilityEnum } from "@enums/items-visibility.enum";
import { Checkbox, Form, Select, Space } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { ITopping } from "../../../interfaces/components/topping";
import { settingSelector } from "../../../redux/slices/settingSlice";
import { useAppSelector } from "../../../redux/store";
import { formatPriceBaseOnCurrency } from "../../../utils";
import s from "./select-topping.module.scss";
import { ServiceEnum } from "../../../enums/service.enum";
import { env } from "next-runtime-env";

export const SelectTopping: React.FC<ITopping.IProp> = (props) => {
    const { linkedProductGroups, onChange } = props;
    const [form] = Form.useForm();

    const { t } = useTranslation("common");

    const { currencyConfig } = useAppSelector(settingSelector);
    const isFFood = env("NEXT_PUBLIC_MAIN_LOGO") === ServiceEnum.FFOOD;

    useEffect(() => {
        linkedProductGroups?.forEach((group) => {
            const productIds: string[] = [];
            group.linkedProducts.forEach((product) => {
                if (product.qty) {
                    productIds.push(product.id);
                }
            });

            form.setFieldValue(group.id.toString(), productIds);
        });
    }, [linkedProductGroups]);

    const validateForm = async () => {
        try {
            await form.validateFields();
            return true;
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    const getValue = (id: string): any => {
        const toppingGroup = linkedProductGroups.find(
            (group) => group.id === id,
        );

        if (!toppingGroup) {
            return [];
        }

        return toppingGroup.linkedProducts?.map((lp) => {
            if (lp.qty) {
                return lp.id;
            }
        });
    };

    const linkedProductDisable = (toppingGroupIndex: number): boolean => {
        const qty = linkedProductGroups[
            toppingGroupIndex
        ]?.linkedProducts.reduce((acc, obj) => {
            return acc + obj.qty;
        }, 0);

        if (
            qty === linkedProductGroups[toppingGroupIndex]?.maxTopping &&
            linkedProductGroups[toppingGroupIndex]?.maxTopping
        ) {
            return true;
        }

        return false;
    };

    const onchange = async (value: CheckboxValueType[], index: number) => {
        form.setFieldValue(linkedProductGroups[index]?.id, value);
        const valid = await validateForm();
        onChange(value, index, valid);
    };

    return linkedProductGroups?.length ? (
        <div
            className={`${s.productToppingWrapper} ${
                isFFood ? `${s.ffoodProductTopping} ffood` : ""
            }`}
        >
            <Form
                name="dynamic_form_nest_item"
                autoComplete="off"
                size="large"
                layout="vertical"
                form={form}
            >
                {linkedProductGroups.map((lpg, index) => {
                    if (
                        lpg.linkedProducts.findIndex(
                            (product) =>
                                product?.visibility ===
                                ItemVisibilityEnum.VISIBLE,
                        ) > -1
                    ) {
                        return (
                            <div key={lpg.id}>
                                <Form.Item
                                    label={
                                        <p className="text-base text-neutral-60 font-semibold font-sans mb-2">
                                            {lpg.name}
                                            <span className="ml-1 font-normal text-xs text-neutral-30">
                                                (
                                                <>
                                                    {!lpg.required && (
                                                        <span>
                                                            {t(
                                                                "order.optional",
                                                            )}
                                                            :{" "}
                                                        </span>
                                                    )}
                                                    {t("order.max", {
                                                        count: lpg.maxTopping,
                                                    })}
                                                </>
                                                )
                                            </span>
                                        </p>
                                    }
                                    required={lpg.required}
                                    rules={[
                                        {
                                            required: lpg.required,
                                            message: `${t(
                                                "order.topping_required",
                                            )}`,
                                        },
                                    ]}
                                    name={`${lpg.id}`}
                                    key={lpg.id}
                                >
                                    <Select className="hidden" />
                                </Form.Item>

                                <Checkbox.Group
                                    className="w-full"
                                    onChange={(value) => onchange(value, index)}
                                    defaultValue={getValue(lpg.id)}
                                >
                                    {isFFood ? (
                                        <Space
                                            direction="vertical"
                                            className="w-full"
                                            key={`group_${lpg.id}`}
                                        >
                                            {lpg?.linkedProducts.map((lp) => (
                                                <div
                                                    className="topping-option"
                                                    key={lp.id}
                                                >
                                                    <p className="topping-name">
                                                        {lp?.name}
                                                    </p>
                                                    <Checkbox
                                                        value={lp.id}
                                                        key={lp.id}
                                                        disabled={
                                                            lp.visibility ===
                                                                ItemVisibilityEnum.UNAVAILABLE ||
                                                            (linkedProductDisable(
                                                                index,
                                                            ) &&
                                                                !lp.qty)
                                                        }
                                                        className="flex-row-reverse"
                                                    >
                                                        <p
                                                            className={`price ${s.priceText}`}
                                                        >
                                                            {lp?.discount
                                                                ? `+ ${formatPriceBaseOnCurrency(
                                                                      lp
                                                                          ?.discount
                                                                          ?.price,
                                                                      currencyConfig?.config,
                                                                  )}`
                                                                : lp?.price !==
                                                                      0 &&
                                                                  `+ ${formatPriceBaseOnCurrency(
                                                                      lp?.price,
                                                                      currencyConfig?.config,
                                                                  )}`}
                                                        </p>
                                                    </Checkbox>
                                                </div>
                                            ))}
                                        </Space>
                                    ) : (
                                        <Space
                                            direction="vertical"
                                            className="w-full"
                                            key={`group_${lpg.id}`}
                                        >
                                            {lpg?.linkedProducts.map((lp) => (
                                                <div
                                                    className="topping-option"
                                                    key={lp.id}
                                                >
                                                    <Checkbox
                                                        value={lp.id}
                                                        key={lp.id}
                                                        disabled={
                                                            lp.visibility ===
                                                                ItemVisibilityEnum.UNAVAILABLE ||
                                                            (linkedProductDisable(
                                                                index,
                                                            ) &&
                                                                !lp.qty)
                                                        }
                                                    >
                                                        <p className="topping-name">
                                                            {lp?.name}
                                                        </p>
                                                    </Checkbox>
                                                    <p className="price">
                                                        {lp?.discount
                                                            ? `+ ${formatPriceBaseOnCurrency(
                                                                  lp?.discount
                                                                      ?.price,
                                                                  currencyConfig?.config,
                                                              )}`
                                                            : lp?.price !== 0 &&
                                                              `+ ${formatPriceBaseOnCurrency(
                                                                  lp?.price,
                                                                  currencyConfig?.config,
                                                              )}`}
                                                    </p>
                                                </div>
                                            ))}
                                        </Space>
                                    )}
                                </Checkbox.Group>
                            </div>
                        );
                    }
                })}
            </Form>
        </div>
    ) : (
        <></>
    );
};
