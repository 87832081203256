import s from "./number-input.module.scss";

import { validatePrice } from "@utils/validator";
import { Input } from "antd";
import { useEffect, useState } from "react";
import { NCurrency } from "../../../interfaces";
import { modifyFloatPrice } from "../../../utils";

export const NumberInput: React.FC<{
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: string | number;
    isPrice?: boolean;
    hasSymbols?: boolean;
    isInteger?: boolean;
    currency?: NCurrency.ICurrencyInfo | null;
    onChange?: (value: string | number) => void;
}> = ({
    hasSymbols = true,
    placeholder = "",
    disabled = false,
    isPrice = false,
    defaultValue = "",
    isInteger = false,
    currency,
    onChange,
}) => {
    const [value, setValue] = useState<string | number>("");

    useEffect(() => {
        setValue(
            defaultValue
                ? isPrice
                    ? defaultValue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : modifyFloatPrice(defaultValue as string)
                : "",
        );
    }, [defaultValue]);

    const validateNumber = (_value: string): string | undefined => {
        if (!_value || validatePrice(_value, currency?.code)) {
            const price = modifyFloatPrice(_value);
            return price ? price.toString() : "";
        }
    };

    const handleChange = (_value: string) => {
        if (isPrice) {
            const realValue = _value.replaceAll(",", "");
            const handleValue = validateNumber(realValue);
            if (handleValue) {
                const formatValue = handleValue.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ",",
                );
                setValue(formatValue);
                onChange && onChange(parseFloat(handleValue));
            } else if (handleValue === "") {
                setValue(handleValue);
                onChange && onChange("");
            }
        } else {
            if (
                !_value || isInteger
                    ? /^[0-9]*$/.test(_value)
                    : /^\d+(\.\d{0,2})?$/.test(_value)
            ) {
                const handleValue = modifyFloatPrice(_value);
                setValue(handleValue);
                onChange && onChange(handleValue);
            }
        }
    };

    return (
        <Input
            addonBefore={
                hasSymbols && isPrice && currency && currency.config.symbol
            }
            addonAfter={hasSymbols && !isPrice && "%"}
            placeholder={placeholder}
            onChange={(event) => handleChange(event?.target?.value ?? "")}
            value={value}
            disabled={disabled}
            className={s.numberInputContainer}
        />
    );
};
