import s from "./gift-card-list.module.scss";

import { GiftCardItemTemplate } from "@components/modules/gift-card-item";
import { GiftCardItemTypeEnum } from "@enums/gift-card-item-type.enum";
import { IGiftCard } from "@interfaces/components/gift-card";
import { IGiftCardCode } from "@interfaces/components/gift-card-code";
import { GiftCardCodeGraphQL } from "@services/apollo/gift-card-code.query";
import { Image, Spin } from "antd";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import { DrawerCustom } from "../../custom-drawer";
import { GiftCardNotFound } from "../../gift-card-not-found";

type GiftCardEmailDrawerProps = {
    isShow: boolean;
    giftCardUseIds: string[];
    handleCancel: () => void;
    handleOk: (value: IGiftCardCode.Request) => void;
    isMobile: boolean;
};

const giftCardCodeQuery = new GiftCardCodeGraphQL();

export const GiftCardListDrawer: FC<GiftCardEmailDrawerProps> = ({
    isShow,
    giftCardUseIds,
    handleCancel,
    handleOk,
    isMobile,
}) => {
    const { t } = useTranslation("common");
    const [giftCards, setGiftCards] = useState<IGiftCard.Request[]>([]);

    const { data, loading, error, refetch } = giftCardCodeQuery.getList({
        start: 0,
        limit: 0,
        isNotExpired: true,
        isDisable: false,
        isNotOutOfBalance: true,
    });

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (data && !error) {
            const giftCards: IGiftCard.Request[] = data.map((giftCardCode) => ({
                ...giftCardCode.giftCard,
                id: giftCardCode.id,
                remainingBalance: giftCardCode.remainingBalance,
                expiredTime: giftCardCode.expiredTime,
            }));

            const giftCardNotUse = giftCards.filter(
                (gc) => !giftCardUseIds.includes(gc.id),
            );

            setGiftCards(giftCardNotUse);
        } else {
            setGiftCards([]);
        }
    }, [data, giftCardUseIds]);

    const handleUseGiftCardCode = (id: string) => {
        const gcCode = data?.find((gc) => gc.id === id);

        if (gcCode) {
            handleOk(gcCode);
        }
    };

    return (
        <DrawerCustom
            key="gift-card-list"
            title={t("order.gift_card")}
            getContainer={false}
            open={isShow}
            onClose={handleCancel}
            height={610}
            footer={null}
            placement={isMobile ? "bottom" : "right"}
            className={s.giftCardListDrawer}
            closeIcon={<Image src="/icons/bt_close.svg" preview={false} />}
        >
            {loading ? (
                <div className="flex item-center justify-center">
                    <Spin spinning />
                </div>
            ) : (
                <>
                    {giftCards?.length > 0 && !error ? (
                        giftCards?.map((giftCard) => (
                            <div key={giftCard.id} className={`w-full mb-2`}>
                                <GiftCardItemTemplate
                                    isHigher
                                    isMobile={isMobile}
                                    giftCard={giftCard}
                                    type={GiftCardItemTypeEnum.ACTIVE}
                                    key={giftCard.id}
                                    handleShowDetail={() => {
                                        handleUseGiftCardCode(giftCard.id);
                                    }}
                                    handleUse={() => {
                                        handleUseGiftCardCode(giftCard.id);
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <GiftCardNotFound isHideBackBtn />
                    )}
                </>
            )}
        </DrawerCustom>
    );
};
