import { Drawer, DrawerProps, Image } from "antd";
import s from "./custom-drawer.module.scss";

interface IDrawerCustom extends DrawerProps {
    key: string;
    width?: number;
    closeIcon?: JSX.Element;
}

export const DrawerCustom: React.FC<IDrawerCustom> = ({
    children,
    ...props
}) => {
    const {
        placement,
        onClose,
        open,
        width = 600,
        key,
        className,
        title,
        height,
        footer,
        footerStyle,
        zIndex,
        closeIcon,
    } = props;

    return (
        <Drawer
            title={title}
            placement={placement}
            onClose={onClose}
            open={open}
            key={key}
            height={height ? height : "100%"}
            closeIcon={
                closeIcon ? (
                    closeIcon
                ) : (
                    <Image src="/icons/bt_close.svg" preview={false} />
                )
            }
            width={width}
            className={`${className} ${s.customDrawer}`}
            footer={footer}
            footerStyle={footerStyle}
            zIndex={zIndex}
        >
            {children}
        </Drawer>
    );
};
