import s from "./gift-card-not-found.module.scss";

import { FC } from "react";
import { Button } from "antd";
import { useRouter } from "next/navigation";
import { useTranslation } from "next-i18next";
import { useAppSelector } from "@redux/store";
import { guestSelector } from "@redux/slices/guestSlice";

type GiftCardNotFoundProps = {
    isHideBackBtn?: boolean;
    isModal?: boolean;
    textBtn?: string;
    textNoResult?: string;
    textDescription?: string;
    handleBack?: () => void;
};

export const GiftCardNotFound: FC<GiftCardNotFoundProps> = ({
    isHideBackBtn = false,
    isModal = false,
    textBtn,
    textNoResult,
    textDescription,
    handleBack,
}) => {
    const router = useRouter();
    const { t } = useTranslation("common");

    const guest = useAppSelector(guestSelector);

    const backToMenu = () => {
        if (handleBack) {
            handleBack();
        } else {
            router.push("/");
        }
    };

    return (
        <section className={s.notFoundContainer}>
            <div className={isModal ? "" : isHideBackBtn ? "mt-5" : "mt-40"}>
                <img
                    src="/icons/no-result.svg"
                    alt="no-result"
                    className="m-auto"
                />
                <p className={`${s.notResult} ${isModal ? "mt-7" : "mt-9"}`}>
                    {textNoResult || t("common.not_found")}
                </p>
                <p className={s.notFound}>
                    {textDescription
                        ? textDescription
                        : guest?.id
                        ? t("gift_card.not_result_detail")
                        : t("gift_card.guest_not_show_my_gift_card")}
                </p>

                {!isHideBackBtn && (
                    <Button
                        type="primary"
                        className={`${s.btnHome} ${isModal ? "mt-8" : "mt-12"}`}
                        onClick={() => backToMenu()}
                    >
                        {textBtn || t("order.back_to_home")}
                    </Button>
                )}
            </div>
        </section>
    );
};
