export const ZaloPayIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_545_2148)">
                <path
                    d="M13.8169 3.87538C13.6346 3.68919 13.4029 3.5951 13.1206 3.5951C12.7066 3.5951 12.4046 3.79417 12.2144 4.19132C11.8084 3.71395 11.2537 3.47626 10.5496 3.47626C9.70479 3.47626 8.99468 3.80209 8.41927 4.45377C7.84385 5.10544 7.55664 5.90072 7.55664 6.83762C7.55664 7.77453 7.84385 8.56981 8.41927 9.22148C8.99468 9.87315 9.70578 10.199 10.5496 10.199C11.2528 10.199 11.8084 9.96031 12.2144 9.48393C12.4046 9.88207 12.7066 10.0801 13.1206 10.0801C13.4019 10.0801 13.6336 9.98705 13.8169 9.79987C13.9991 9.61367 14.0902 9.38489 14.0902 9.11452V4.56073C14.0902 4.29035 13.9991 4.06257 13.8169 3.87538ZM11.7985 7.96666C11.5627 8.27071 11.2369 8.42323 10.8229 8.42323C10.4089 8.42323 10.0841 8.2717 9.8474 7.96666C9.61169 7.66162 9.49383 7.28627 9.49383 6.83762C9.49383 6.38898 9.61169 6.01362 9.8474 5.70859C10.0831 5.40454 10.408 5.25202 10.8229 5.25202C11.2379 5.25202 11.5618 5.40355 11.7985 5.70859C12.0342 6.01263 12.152 6.38898 12.152 6.83762C12.152 7.28627 12.0342 7.66262 11.7985 7.96666Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M16.284 9.79986C16.1017 9.98704 15.87 10.0801 15.5887 10.0801C15.3075 10.0801 15.0747 9.98704 14.8925 9.79986C14.7103 9.61367 14.6191 9.38489 14.6191 9.11451V0.965625C14.6191 0.69525 14.7103 0.466471 14.8925 0.280279C15.0747 0.0930962 15.3065 0 15.5887 0C15.871 0 16.1017 0.0930962 16.284 0.280279C16.4662 0.467462 16.5573 0.696241 16.5573 0.965625V9.11451C16.5573 9.38489 16.4662 9.61367 16.284 9.79986Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M22.7035 4.45377C22.0825 3.80209 21.2912 3.47626 20.3305 3.47626C19.3698 3.47626 18.5854 3.79813 17.9773 4.44188C17.3683 5.08563 17.0642 5.88388 17.0642 6.83762C17.0642 7.79137 17.3683 8.58962 17.9773 9.23337C18.5864 9.87712 19.3698 10.199 20.3305 10.199C21.2912 10.199 22.0825 9.87315 22.7035 9.22148C23.2997 8.58565 23.5978 7.79137 23.5978 6.83762C23.5978 5.88388 23.2997 5.08959 22.7035 4.45377ZM21.306 7.96666C21.0703 8.27071 20.7445 8.42323 20.3305 8.42323C19.9165 8.42323 19.5917 8.2717 19.356 7.96666C19.1202 7.66162 19.0014 7.28627 19.0014 6.83762C19.0014 6.38898 19.1193 6.01362 19.356 5.70859C19.5917 5.40454 19.9165 5.25202 20.3305 5.25202C20.7445 5.25202 21.0693 5.40355 21.306 5.70859C21.5417 6.01263 21.6596 6.38898 21.6596 6.83762C21.6596 7.28627 21.5417 7.66262 21.306 7.96666Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M6.48404 3.70206C7.16839 2.81864 7.51107 2.22441 7.51107 1.91739C7.51107 1.20926 7.06539 0.854706 6.17306 0.854706H1.46972C1.1003 0.854706 0.814082 0.94186 0.611053 1.11617C0.408024 1.29147 0.307005 1.51826 0.307005 1.79656C0.307005 2.07486 0.408024 2.30364 0.611053 2.47795C0.814082 2.65225 1.09931 2.73941 1.46972 2.73941H4.69936L0.563515 8.08353C0.29314 8.43313 0.158447 8.74312 0.158447 9.0135C0.158447 9.78996 0.672457 10.1792 1.69949 10.1792H6.55138C7.32685 10.1792 7.71409 9.85433 7.71409 9.20365C7.71409 8.55297 7.32685 8.22812 6.55138 8.22812H2.98303L6.48305 3.70206H6.48404Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M4.01003 15.2123H2.80176V17.723H4.01003C4.3735 17.723 4.66764 17.6041 4.89246 17.3674C5.11728 17.1307 5.23018 16.8306 5.23018 16.4671C5.23018 16.1037 5.11728 15.8036 4.89246 15.5669C4.66764 15.3302 4.3735 15.2113 4.01003 15.2113V15.2123Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M10.0365 17.5724C9.65318 17.5724 9.3521 17.713 9.13421 17.9943C8.91633 18.2756 8.8064 18.6242 8.8064 19.0391C8.8064 19.4541 8.91534 19.8027 9.13421 20.084C9.35309 20.3653 9.65318 20.5059 10.0365 20.5059C10.4197 20.5059 10.7208 20.3653 10.9387 20.084C11.1566 19.8017 11.2665 19.4541 11.2665 19.0391C11.2665 18.6242 11.1576 18.2766 10.9387 17.9943C10.7198 17.713 10.4187 17.5724 10.0365 17.5724Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M18.5697 11.6241H1.05773C0.473404 11.6241 0 12.0975 0 12.6819V22.9413C0 23.5256 0.473404 23.999 1.05773 23.999H14.8815C14.7359 23.8604 14.6627 23.6989 14.6627 23.5137C14.6627 23.3622 14.6963 23.2037 14.7647 23.0373L15.4163 21.5874L13.6128 17.0861C13.5604 16.9574 13.5336 16.8177 13.5336 16.6662C13.5336 16.4701 13.6168 16.3037 13.7832 16.167C13.9496 16.0314 14.1229 15.963 14.3051 15.963C14.7052 15.963 14.9776 16.1561 15.1212 16.5414L16.2443 19.6136L17.4466 16.5414C17.5982 16.1561 17.8695 15.963 18.2627 15.963C18.4439 15.963 18.6182 16.0314 18.7846 16.167C18.951 16.3027 19.0342 16.4691 19.0342 16.6662C19.0342 16.8177 19.0075 16.9574 18.955 17.0861L16.2849 23.5702C16.2116 23.7514 16.1235 23.894 16.0215 23.999H18.5697C19.154 23.999 19.6274 23.5256 19.6274 22.9413V12.6819C19.6274 12.0975 19.154 11.6241 18.5697 11.6241ZM6.2008 18.5271C5.64817 19.0322 4.95292 19.2848 4.11703 19.2848H2.80279V21.2269C2.80279 21.5191 2.7196 21.7459 2.5542 21.9073C2.38881 22.0687 2.17489 22.15 1.91441 22.15C1.65394 22.15 1.44101 22.0687 1.27463 21.9073C1.10923 21.7459 1.02604 21.5191 1.02604 21.2269V14.5725C1.02604 13.9565 1.33405 13.6485 1.95007 13.6485H4.04572C4.92221 13.6485 5.64618 13.9109 6.21863 14.4358C6.79107 14.9608 7.07729 15.6421 7.07729 16.478C7.07729 17.3139 6.78513 18.0052 6.2008 18.5261V18.5271ZM13.0602 21.1467C13.0602 21.3973 12.976 21.6082 12.8077 21.7805C12.6383 21.9539 12.4244 22.04 12.1639 22.04C11.7806 22.04 11.5013 21.8568 11.3251 21.4884C10.9487 21.9301 10.4357 22.15 9.785 22.15C9.00359 22.15 8.34597 21.8489 7.81315 21.2457C7.28032 20.6426 7.0149 19.9067 7.0149 19.0392C7.0149 18.1716 7.28131 17.4357 7.81315 16.8326C8.34597 16.2294 9.00359 15.9284 9.785 15.9284C10.4357 15.9284 10.9497 16.1492 11.3251 16.5899C11.5013 16.2225 11.7806 16.0383 12.1639 16.0383C12.4244 16.0383 12.6393 16.1245 12.8077 16.2978C12.976 16.4711 13.0602 16.682 13.0602 16.9326V21.1467Z"
                    fill="#E4E6EA"
                />
            </g>
            <defs>
                <clipPath id="clip0_545_2148">
                    <rect width="23.5979" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
