"use client";

import { useState, useEffect } from "react";

const useCheckMobileScreen = (): boolean => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const handleWindowSizeChange = () => setWidth(window.innerWidth);
        handleWindowSizeChange();
        window.addEventListener("resize", handleWindowSizeChange);
        return () =>
            window.removeEventListener("resize", handleWindowSizeChange);
    }, []);

    // return width < 460;
    return true;
};

export default useCheckMobileScreen;
