import { Radio, RadioChangeEvent } from "antd";
import React from "react";

import { NProduct } from "../../interfaces/components/product";
import { guestSelector } from "../../redux/slices/guestSlice";
import { settingSelector } from "../../redux/slices/settingSlice";
import { useAppSelector } from "../../redux/store";
import { formatPriceBaseOnCurrency } from "../../utils";
import s from "./product-size-template.module.scss";
import { ServiceEnum } from "../../enums/service.enum";

import { env } from "next-runtime-env";

export const ProductSizeTemplate: React.FC<{
    header?: string;
    sizes: NProduct.IProductSize[];
    onChange?: (sizeId: string) => void;
    productPrice?: number;
    defaultSizeId?: string;
}> = ({ header, sizes, productPrice, onChange, defaultSizeId }) => {
    const guest = useAppSelector(guestSelector);
    const onSizeChange = (e: RadioChangeEvent) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };
    const { currencyConfig } = useAppSelector(settingSelector);
    const isFFood = env("NEXT_PUBLIC_MAIN_LOGO") === ServiceEnum.FFOOD;

    return (
        <>
            <div
                className={`${s.productSizeWrapper} ${
                    isFFood ? `${s.ffoodProductSizeWrapper} ffood` : ""
                }`}
            >
                {header && <div className={s.sizeHeader}>{header}</div>}
                <Radio.Group
                    className={s.productSizeOptionGroup}
                    defaultValue={defaultSizeId}
                    onChange={onSizeChange}
                >
                    {sizes.map((size) => (
                        <React.Fragment key={size.id}>
                            {isFFood ? (
                                <div className="flex justify-between">
                                    <span className={`option ${s.sizeText}`}>
                                        {size.size}
                                    </span>

                                    <Radio
                                        value={size.id}
                                        className="flex-row-reverse"
                                    >
                                        {productPrice
                                            ? Number(
                                                  size?.discount
                                                      ? size.discount.price
                                                      : size.price,
                                              ) -
                                                  Number(productPrice) >
                                                  0 && (
                                                  <span
                                                      className={`text mr-3 ${s.priceText}`}
                                                  >
                                                      +
                                                      {formatPriceBaseOnCurrency(
                                                          Number(
                                                              size?.discount
                                                                  ? size
                                                                        .discount
                                                                        .price
                                                                  : size.price,
                                                          ) -
                                                              Number(
                                                                  productPrice,
                                                              ),
                                                          currencyConfig?.config,
                                                      )}
                                                  </span>
                                              )
                                            : null}
                                    </Radio>
                                </div>
                            ) : (
                                <Radio value={size.id}>
                                    <div className="flex justify-between">
                                        <span className="option">
                                            {size.size}
                                        </span>
                                        <>
                                            <span className="text">
                                                +{" "}
                                                {formatPriceBaseOnCurrency(
                                                    Number(
                                                        size?.discount
                                                            ? size.discount
                                                                  .price
                                                            : size.price,
                                                    ) - Number(productPrice),
                                                    currencyConfig?.config,
                                                )}
                                            </span>
                                        </>
                                    </div>
                                </Radio>
                            )}
                        </React.Fragment>
                    ))}
                </Radio.Group>
            </div>
        </>
    );
};
