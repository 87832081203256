import { Checkbox, Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useTranslation } from "next-i18next";
import { NCart } from "../../interfaces/components/cart";
import { NProduct } from "../../interfaces/components/product";
import { guestSelector } from "../../redux/slices/guestSlice";
import { useAppSelector } from "../../redux/store";
import s from "./product-option-type-template.module.scss";
import { ServiceEnum } from "../../enums/service.enum";

import { env } from "next-runtime-env";
export interface ISelectOptions {
    [id: string]: {
        options: Array<{ id: string; name: string }>;
        isMultiChoice?: boolean;
        name: string;
        optionTypeId: string;
    };
}
const CheckboxGroup = Checkbox.Group;
export const ProductOptionTypeTemplate: React.FC<{
    title?: string;
    optionTypes: NProduct.IProductOptionType[];
    onChange?: (selectOptions: ISelectOptions) => void;
    selectedOptionTypes?: NCart.ISelectOptionType[];
}> = ({ optionTypes, onChange, selectedOptionTypes, title }) => {
    const [selectOptions, setSelectOptions] = useState<ISelectOptions>({});
    const guest = useAppSelector(guestSelector);
    const isFFood = env("NEXT_PUBLIC_MAIN_LOGO") === ServiceEnum.FFOOD;
    const { t } = useTranslation("common");
    const onChangeOptions = (
        optionType: NProduct.IProductOptionType,
        optionIds: string[],
        optionTypeIndex: number,
    ): void => {
        const options: Array<{ id: string; name: string }> = [];
        optionIds?.forEach((id) => {
            const option = optionTypes[optionTypeIndex].productOptions?.find(
                (po) => po.id === id,
            );
            if (option) {
                options.push({
                    id: option?.id,
                    name: option?.name,
                });
            }
        });

        setSelectOptions({
            ...selectOptions,
            [optionType.id]: {
                options: options,
                isMultiChoice: optionType?.isMultiChoice,
                name: optionType.name,
                optionTypeId: optionType.optionTypeId,
            },
        });
    };

    useMemo(() => {
        if (onChange) {
            onChange(selectOptions);
        }
    }, [selectOptions]);

    useEffect(() => {
        if (selectedOptionTypes?.length) {
            let options = selectOptions;
            selectedOptionTypes.forEach((data) => {
                options = {
                    ...options,
                    [data?.id]: {
                        options: data?.options,
                        isMultiChoice: data?.isMultiChoice,
                        name: data?.name,
                        optionTypeId: data?.optionTypeId,
                    },
                };
            });

            setSelectOptions(options);
        }
    }, [selectedOptionTypes]);

    return (
        <div
            className={`${s.productOptionTypeWrapper} ${
                isFFood ? `${s.ffoodProductOption} ffood` : ""
            }`}
        >
            <p className={`text ${s.optionHeader}`}>{title}</p>
            {optionTypes.map((optionType, optionTypeIndex) => (
                <div className={s.productOptionType} key={optionType.id}>
                    <div className="flex text mb-2 items-center">
                        {optionType.name}
                        {!optionType.isMultiChoice ? (
                            <span className="label ml-1 danger">(*)</span>
                        ) : (
                            <span className="label ml-1">
                                ({t("common.optional")})
                            </span>
                        )}
                    </div>
                    <div className={s.productOptionTypeOption}>
                        {optionType.isMultiChoice ? (
                            <CheckboxGroup
                                options={optionType.productOptions.map(
                                    (item) => {
                                        return {
                                            label: (
                                                <span className="option">
                                                    {item.name || "Noname"}
                                                </span>
                                            ),
                                            value: item.id,
                                            key: item.id,
                                        };
                                    },
                                )}
                                value={
                                    selectOptions[optionType.id]?.options?.map(
                                        (option) => option.id,
                                    ) || []
                                }
                                onChange={(checked: CheckboxValueType[]) => {
                                    onChangeOptions(
                                        optionType,
                                        checked as string[],
                                        optionTypeIndex,
                                    );
                                }}
                                className="flex-row-reverse"
                            />
                        ) : (
                            <Radio.Group
                                className={s.productSizeOptionGroup}
                                value={
                                    selectOptions[optionType.id]?.options[0]
                                        ?.id || null
                                }
                                onChange={(e: RadioChangeEvent) => {
                                    onChangeOptions(
                                        optionType,
                                        [e.target.value],
                                        optionTypeIndex,
                                    );
                                }}
                            >
                                {optionType.productOptions.map((option) => (
                                    <Radio value={option.id} key={option.id}>
                                        <p className="option">{option.name}</p>
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
