import s from "./gift-card-code.module.scss";

import { IGiftCardCode } from "@interfaces/components/gift-card-code";
import { GiftCardCodeGraphQL } from "@services/apollo/gift-card-code.query";
import { Button, Form, Image, Input, Spin, notification } from "antd";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect } from "react";
import { DrawerCustom } from "../../custom-drawer";

type GiftCardCodeDrawerProps = {
    isShow: boolean;
    giftCardUseIds: string[];
    handleCancel: () => void;
    handleOk: (value: IGiftCardCode.Request) => void;
    isMobile: boolean;
};

const giftCardCodeQuery = new GiftCardCodeGraphQL();

export const GiftCardCodeDrawer: FC<GiftCardCodeDrawerProps> = ({
    isShow,
    giftCardUseIds,
    handleCancel,
    handleOk,
    isMobile,
}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation("common");

    const { data, loading, error, refetch } = giftCardCodeQuery.getByCode();

    const handleSubmit = useCallback(() => {
        const code = form.getFieldValue("code");

        if (code) {
            refetch({ variables: { code } });
            form.resetFields();
        }
    }, []);

    useEffect(() => {
        if (data && !loading && !error) {
            if (giftCardUseIds?.includes(data?.id)) {
                notification.error({
                    className: "error-notification",
                    message: "",
                    description: t("gift_card.error.gift_card_used"),
                });
            } else {
                handleOk(data);
            }
        }
    }, [data, loading]);

    const Footer = () => (
        <Button
            type="primary"
            className="h-auto p-4 text-base font-bold"
            onClick={handleSubmit}
        >
            {t("common.next")}
        </Button>
    );

    return (
        <DrawerCustom
            key="gift-card-code"
            title={t("order.gift_card")}
            getContainer={false}
            open={isShow}
            onClose={handleCancel}
            placement={isMobile ? "bottom" : "right"}
            className={s.giftCardCodeDrawer}
            closeIcon={<Image src="/icons/bt_close.svg" preview={false} />}
            footer={<Footer />}
            height={610}
        >
            {loading ? (
                <div className="flex items-center justify-center">
                    <Spin spinning className="w-full" />
                </div>
            ) : (
                <>
                    <Form form={form} size="large" layout="vertical">
                        <Form.Item
                            name="code"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "gift_card.redeem_code_required",
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    "gift_card.redeem_code_placeholder",
                                )}
                                className={`${s.inputSearch} w-full`}
                            />
                        </Form.Item>
                    </Form>
                </>
            )}
        </DrawerCustom>
    );
};
