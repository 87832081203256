import { NCart } from "@interfaces/index";
import { settingSelector } from "@redux/slices";
import { useAppSelector } from "@redux/store";
import { formatPriceBaseOnCurrency } from "@utils/index";
import { Button } from "antd";
import s from "./product-in-cart.module.scss";
import { useTranslation } from "next-i18next";

export type ActionProps = {
    quantity?: number;
    disableCart?: boolean;
    cartItemTotal: number;
    cartItem?: NCart.ICartItem;
    toppingValid?: boolean;
    removeCartItem: () => void;
    onClose?: () => void;
    checkOrderItemTypeChange: () => void;
};

export const Action = (props: ActionProps) => {
    const {
        cartItem,
        removeCartItem,
        cartItemTotal,
        disableCart,
        quantity,
        onClose,
        checkOrderItemTypeChange,
        toppingValid,
    } = props;
    const { currencyConfig } = useAppSelector(settingSelector);
    const { t } = useTranslation("common");

    if (cartItem) {
        if (!quantity) {
            return (
                <Button
                    className={s.removeItem}
                    type="primary"
                    onClick={removeCartItem}
                >
                    <p className="add-to-cart">{t("common.remove")}</p>
                </Button>
            );
        }
    } else {
        if (!quantity) {
            return (
                <Button
                    className={s.buttonAddToCart}
                    type="primary"
                    onClick={onClose}
                >
                    <p className={s.addToCart}>{t("common.back")}</p>
                </Button>
            );
        }
    }

    return (
        <Button
            className={s.buttonAddToCart}
            type="primary"
            onClick={checkOrderItemTypeChange}
            disabled={disableCart || !quantity || !toppingValid}
        >
            <p className={s.addToCart}>
                {cartItem ? t("common.edit_item") : t("common.confirm")}
                <span className="mx-2">-</span>
                {formatPriceBaseOnCurrency(
                    cartItemTotal * quantity,
                    currencyConfig?.config,
                )}
            </p>
        </Button>
    );
};
