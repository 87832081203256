import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useTranslation } from "react-i18next";
import { ImagesSlick } from "../../../components/images-slick";
import { SelectTopping } from "../../../components/modules/select-topping";
import { NCart, NProduct } from "../../../interfaces";
import {
    ISelectOptions,
    ProductOptionTypeTemplate,
} from "../../product-option-type";
import { ProductSizeTemplate } from "../../product-size";
import { Block } from "../block";
import { ProductInfo } from "../product-info";

type Prop = {
    isEdit: boolean;
    product: NProduct.IProduct;
    toppingGroups: NProduct.ILinkedProductGroups[];
    defaultSizeId?: string;
    cartItem: NCart.ICartItem | undefined;
    onToppingChange: (
        value: CheckboxValueType[],
        index: number,
        valid: boolean,
    ) => void;
    onSizeChange: (id: string) => void;
    onChangeOptions: (option: ISelectOptions) => void;
};

export const SimpleProduct = (props: Prop) => {
    const { t } = useTranslation("common");
    const {
        isEdit,
        product,
        toppingGroups,
        defaultSizeId,
        cartItem,
        onSizeChange,
        onToppingChange,
        onChangeOptions,
    } = props;
    return (
        <>
            {isEdit ? (
                <Block className="mb-4 flex items-center gap-2">
                    <img alt="edit" src="/icons/edit.svg" className="w-6 h-6" />
                    <p className="text-lg text-neutral-60 font-semibold font-sans">
                        {product.name}
                    </p>
                </Block>
            ) : (
                <Block className="divider">
                    <ProductInfo
                        product={product}
                        hidePhoto={!!product?.gallery?.length}
                    />
                </Block>
            )}
            <Block>
                <ImagesSlick images={product?.gallery || []} />
            </Block>
            {product.productSizes?.length ? (
                <Block className="mb-6">
                    <ProductSizeTemplate
                        header={t("order.select_size")}
                        sizes={product.productSizes}
                        onChange={onSizeChange}
                        productPrice={
                            product?.discount
                                ? product.discount.price
                                : product?.price
                        }
                        defaultSizeId={defaultSizeId}
                    />
                </Block>
            ) : (
                <></>
            )}
            {toppingGroups?.length ? (
                <Block className="mb-6">
                    <SelectTopping
                        linkedProductGroups={toppingGroups}
                        onChange={onToppingChange}
                    />
                </Block>
            ) : (
                <></>
            )}
            {product?.productOptionTypes?.length ? (
                <Block className="divider mb-6">
                    <ProductOptionTypeTemplate
                        title={t("order.options")}
                        optionTypes={product?.productOptionTypes}
                        onChange={onChangeOptions}
                        selectedOptionTypes={cartItem?.selectOptionTypes}
                    />
                </Block>
            ) : (
                <></>
            )}
        </>
    );
};
