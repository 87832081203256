export const CreditIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.5 7.75V16.25C22.5 18.042 21.042 19.5 19.25 19.5H4.75C2.958 19.5 1.5 18.042 1.5 16.25V7.75C1.5 5.958 2.958 4.5 4.75 4.5H19.25C21.042 4.5 22.5 5.958 22.5 7.75ZM8.5 10V9C8.5 8.4475 8.0525 8 7.5 8H5.5C4.9475 8 4.5 8.4475 4.5 9V10C4.5 10.5525 4.9475 11 5.5 11H7.5C8.0525 11 8.5 10.5525 8.5 10ZM7.5 15.25C7.5 14.836 7.164 14.5 6.75 14.5H5.25C4.836 14.5 4.5 14.836 4.5 15.25C4.5 15.664 4.836 16 5.25 16H6.75C7.164 16 7.5 15.664 7.5 15.25ZM11.5 15.25C11.5 14.836 11.164 14.5 10.75 14.5H9.25C8.836 14.5 8.5 14.836 8.5 15.25C8.5 15.664 8.836 16 9.25 16H10.75C11.164 16 11.5 15.664 11.5 15.25ZM15.5 15.25C15.5 14.836 15.164 14.5 14.75 14.5H13.25C12.836 14.5 12.5 14.836 12.5 15.25C12.5 15.664 12.836 16 13.25 16H14.75C15.164 16 15.5 15.664 15.5 15.25ZM19.5 15.25C19.5 14.836 19.164 14.5 18.75 14.5H17.25C16.836 14.5 16.5 14.836 16.5 15.25C16.5 15.664 16.836 16 17.25 16H18.75C19.164 16 19.5 15.664 19.5 15.25ZM19.5 8.75C19.5 8.336 19.164 8 18.75 8H12.25C11.836 8 11.5 8.336 11.5 8.75C11.5 9.164 11.836 9.5 12.25 9.5H18.75C19.164 9.5 19.5 9.164 19.5 8.75Z"
                fill="#E4E6EA"
            />
        </svg>
    );
};
