import { gql, useLazyQuery } from "@apollo/client";
import { IGiftCard } from "@interfaces/components/gift-card";
import { QueryGraphQL } from "@interfaces/components/query-graphql";

import BaseGraphQL from "./baseGraphQL";

export class GiftCardGraphQL extends BaseGraphQL {
    public getList(
        variables: QueryGraphQL.IGetLists,
    ): QueryGraphQL.IResponse<IGiftCard.Request[] | null> {
        const queryName = "getGiftCards";
        const query = gql`
            query ($sort: String, $start: Int, $limit: Int, $moduleType: String, $textSearch: String) {
                ${queryName}(
                    sort: $sort
                    start: $start
                    limit: $limit
                    moduleType: $moduleType
                    textSearch: $textSearch    
                ) {
                    data {
                        id
                        name
                        image
                        balance
                        price
                        expiredDate
                        periodTimeType
                        description
                    }
                    total
                }
            }
        `;

        const [refetch, { data, loading, error }] = useLazyQuery(query, {
            variables: {
                ...variables,
                moduleType: this.getModuleType(),
            },
        });

        return {
            data: data ? data[queryName]?.data : null,
            loading,
            error,
            refetch,
        };
    }

    public getById(
        id: string,
    ): QueryGraphQL.IResponse<IGiftCard.Request | null> {
        const queryName = "getGiftCard";
        const query = gql`
            query ($id: ID!) {
                ${queryName}(id: $id) {
                    id
                    name
                    image
                    balance
                    price
                    expiredDate
                    periodTimeType
                    description
                }
            }
        `;

        const [refetch, { data, loading, error }] = useLazyQuery(query, {
            variables: {
                id,
            },
        });

        return {
            data: data ? data[queryName] : null,
            loading,
            error,
            refetch,
        };
    }
}
