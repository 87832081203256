import s from "./gift-card-item.module.scss";

import { GiftCardItemTypeEnum } from "@enums/gift-card-item-type.enum";
import { ProductTypeEnum } from "@enums/product-type.enum";
import { IGiftCard } from "@interfaces/components/gift-card";
import { settingSelector } from "@redux/slices/settingSlice";
import { useAppSelector } from "@redux/store";
import { formatPriceBaseOnCurrency } from "@utils/format-price";
import { getPublicMediaUrl } from "@utils/resource";
import { displayExpiredDate } from "@utils/string";
import { Button } from "antd";
import { useTranslation } from "next-i18next";
import { FC } from "react";

import { FORMAT_MMMDDYYYY, FORMAT_ll } from "../../../common/constant";
import { UnitEnum } from "../../../enums";
import { addTime, formatDateTime } from "../../../utils";
import { LocaleEnum } from "@enums/locale.enum";
import { NProduct } from "@interfaces/index";

type GiftCardItemProps = {
    giftCard: IGiftCard.Item;
    type: GiftCardItemTypeEnum;
    handleShowDetail: (
        product: IGiftCard.Item,
        categoryId?: string,
        type?: ProductTypeEnum,
    ) => void;
    handleUse?: (id: string) => void;
    quantity?: number;
    isHigher?: boolean;
    isMobile?: boolean;
};

export const GiftCardItemTemplate: FC<GiftCardItemProps> = ({
    giftCard,
    type,
    handleShowDetail,
    handleUse,
    quantity,
    isHigher,
    isMobile,
}) => {
    const { t, i18n } = useTranslation("common");
    const { currencyConfig } = useAppSelector(settingSelector);
    const {
        id,
        image,
        balance,
        price,
        expiredDate,
        periodTimeType,
        expiredTime,
        remainingBalance,
    } = giftCard;

    const handleButtonUse = () => {
        handleUse && handleUse(giftCard.id);
    };

    const handleButtonShowDetail = () => {
        handleShowDetail(giftCard, "", ProductTypeEnum.GIFT_CARD);
    };

    const displayBalanceHeader = () => {
        switch (type) {
            case GiftCardItemTypeEnum.ACTIVE:
            case GiftCardItemTypeEnum.DISABLE:
            case GiftCardItemTypeEnum.EXPIRED:
                return <></>;
            case GiftCardItemTypeEnum.SLICK:
            case GiftCardItemTypeEnum.LIST:
                return (
                    <div className={s.balanceContainer}>
                        {formatPriceBaseOnCurrency(
                            balance,
                            currencyConfig?.config,
                        )}
                    </div>
                );
        }
    };

    const displayExpiredDateHeader = () => {
        if (!expiredDate || !periodTimeType) {
            return <></>;
        }

        switch (type) {
            case GiftCardItemTypeEnum.EXPIRED:
                return (
                    <p className={s.expiredDate}>{t("gift_card.expired")}</p>
                );
            case GiftCardItemTypeEnum.ACTIVE:
            case GiftCardItemTypeEnum.DISABLE:
            case GiftCardItemTypeEnum.SLICK:
            case GiftCardItemTypeEnum.LIST:
                return (
                    <p className={s.expiredDate}>
                        {displayExpiredDate(expiredDate, periodTimeType)}
                    </p>
                );
        }
    };

    const displayPrice = () => {
        switch (type) {
            case GiftCardItemTypeEnum.ACTIVE:
            case GiftCardItemTypeEnum.DISABLE:
            case GiftCardItemTypeEnum.EXPIRED:
                return (
                    <h3 className={`${s.price}`}>
                        <span>
                            {formatPriceBaseOnCurrency(
                                remainingBalance || 0,
                                currencyConfig?.config,
                            )}
                        </span>
                        <span className={`ml-2 ${s.balance}`}>
                            {t("gift_card.balance").toLowerCase()}
                        </span>
                    </h3>
                );
            case GiftCardItemTypeEnum.SLICK:
            case GiftCardItemTypeEnum.LIST:
                return (
                    <h3 className={`${s.price}`}>
                        <span>
                            {formatPriceBaseOnCurrency(
                                price || 0,
                                currencyConfig?.config,
                            )}
                        </span>
                        <span className={`ml-2 line-through ${s.balance}`}>
                            {formatPriceBaseOnCurrency(
                                balance,
                                currencyConfig?.config,
                            )}
                        </span>
                    </h3>
                );
        }
    };

    const displayExpiredTime = (): JSX.Element => {
        switch (type) {
            case GiftCardItemTypeEnum.ACTIVE:
            case GiftCardItemTypeEnum.DISABLE:
            case GiftCardItemTypeEnum.EXPIRED: {
                if (expiredTime) {
                    return (
                        <p className={`${s.expiredText}`}>
                            <span className="font-normal">
                                {t("gift_card.expired_date")}
                            </span>
                            <span className="ml-1">
                                {formatDateTime(
                                    expiredTime,
                                    FORMAT_MMMDDYYYY,
                                    i18n.language,
                                )}
                            </span>
                        </p>
                    );
                }
                return (
                    <p className={`${s.expiredText} mt-1`}>
                        {t("gift_card.no_expiration")}
                    </p>
                );
            }
            case GiftCardItemTypeEnum.SLICK:
            case GiftCardItemTypeEnum.LIST: {
                if (expiredDate && periodTimeType) {
                    return (
                        <p className={`${s.expiredText} mt-1`}>
                            <span className="font-normal">
                                {t("gift_card.expired_date")}
                            </span>
                            <span className="ml-1">
                                {addTime(
                                    expiredDate,
                                    periodTimeType as unknown as UnitEnum,
                                    FORMAT_MMMDDYYYY,
                                    "",
                                    i18n.language,
                                )}
                            </span>
                        </p>
                    );
                }
                return (
                    <p className={`${s.expiredText} mt-1`}>
                        {t("gift_card.no_expiration")}
                    </p>
                );
            }
        }
    };

    const displayButton = () => {
        switch (type) {
            case GiftCardItemTypeEnum.DISABLE:
            case GiftCardItemTypeEnum.EXPIRED:
                return <></>;

            case GiftCardItemTypeEnum.ACTIVE:
                return (
                    <div
                        className={`${s.btnContainer} ${
                            i18n.language === LocaleEnum.VI
                                ? `${s.buttonChange}`
                                : ""
                        }`}
                        onClick={handleButtonUse}
                    >
                        <div className={s.quantity}>
                            <Button type="primary" className={s.use}>
                                {t("gift_card.use")}
                            </Button>
                        </div>
                    </div>
                );
            case GiftCardItemTypeEnum.SLICK:
            case GiftCardItemTypeEnum.LIST:
                return (
                    <div
                        className={s.btnContainer}
                        onClick={handleButtonShowDetail}
                    >
                        <div className={s.quantity}>
                            {quantity ? (
                                <Button
                                    type="primary"
                                    className={s.addQuantityBtn}
                                >
                                    {quantity}{" "}
                                    {t("order.item", {
                                        s: quantity > 1 ? "s" : "",
                                    })}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    icon={
                                        <img
                                            src="/icons/add-item.svg"
                                            alt="add-item-icon"
                                        />
                                    }
                                    className={s.addQuantityBtn}
                                ></Button>
                            )}
                        </div>
                    </div>
                );
        }
    };

    return (
        <section className={s.giftCardItem}>
            <div className="cursor-pointer" onClick={handleButtonShowDetail}>
                <img
                    src={image ? getPublicMediaUrl(image) : "error"}
                    alt="gift-card"
                    className={`${s.imageContainer} ${
                        isHigher && !isMobile ? s.imgHigher : ""
                    } z-0`}
                />

                <div className={`${s.overlay} ${s[type]}`}>
                    {displayBalanceHeader()}
                    {displayExpiredDateHeader()}

                    <div
                        className={`${s.contentContainer} ${
                            quantity ? "mb-2" : ""
                        }`}
                    >
                        <h4
                            className={
                                type === GiftCardItemTypeEnum.DISABLE
                                    ? s.disableName
                                    : s.name
                            }
                        >
                            {giftCard.name}
                        </h4>
                        {displayPrice()}
                        {displayExpiredTime()}
                    </div>
                </div>
            </div>

            {displayButton()}
        </section>
    );
};
