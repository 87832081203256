import { cn } from "@utils/tailwind-marge";

type Props = React.HTMLAttributes<HTMLDivElement>;

export const Block: React.FC<Props> = ({
    className,
    children,
    ...props
}: Props) => (
    <>
        {children && (
            <div {...props} className={cn("px-4", className)}>
                {children}
            </div>
        )}
    </>
);
