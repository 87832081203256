import { formatPriceBaseOnCurrency } from "@utils/format-price";
import { Typography } from "antd";
import { DEFAULT_CURRENCY_CONFIG } from "../../constants";
import { settingSelector } from "../../redux/slices/settingSlice";
import { useAppSelector } from "../../redux/store";
import s from "./discounted-price.module.scss";
const { Text } = Typography;

export const FinalPriceTemplate: React.FC<{
    productPrice: number;
    discountedPrice?: number;
    direction?: "horizontal" | "vertical";
    className?: string;
    priceStyle?: string;
}> = ({ productPrice, discountedPrice, className, direction, priceStyle }) => {
    const { currencyConfig } = useAppSelector(settingSelector);
    let currency = currencyConfig ?? DEFAULT_CURRENCY_CONFIG;
    return (
        <div className={s.discountPriceContainer}>
            {discountedPrice || discountedPrice === 0 ? (
                <>
                    <div
                        className={`${
                            direction === "horizontal"
                                ? s.horizontal
                                : s.vertical
                        } ${className ?? ""}
                    `}
                    >
                        <Text className={s.finalPrice}>
                            {formatPriceBaseOnCurrency(
                                discountedPrice,
                                currency?.config,
                            )}
                        </Text>
                        <Text delete className={s.originPrice}>
                            {formatPriceBaseOnCurrency(
                                productPrice,
                                currency?.config,
                            )}
                        </Text>
                    </div>
                </>
            ) : (
                <span
                    className={`font-semibold final-price ${priceStyle} ${
                        className ?? ""
                    }`}
                >
                    {formatPriceBaseOnCurrency(productPrice, currency?.config)}
                </span>
            )}
        </div>
    );
};
