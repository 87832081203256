import { DrawerCustom } from "@components/modules/custom-drawer";
import { Button, Input } from "antd";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { cn } from "@utils/tailwind-marge";
const { TextArea } = Input;
type Props = {
    note?: string;
    setNote: (note: string) => void;
};

export const OrderNote: React.FC<Props> = ({ setNote, note }) => {
    const { t } = useTranslation("common");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <>
            <section
                id="order-note"
                onClick={() => setIsOpen(true)}
                className={cn(
                    "flex justify-between items-center gap-2 p-4",
                    "hover:bg-neutral-5 cursor-pointer transition",
                )}
            >
                <div className="flex gap-2 items-center">
                    <img alt="note" src="/icons/notes.svg" />
                    <p className="text-base text-neutral-60 font-semibold font-sans">
                        {t("order.note")}
                    </p>
                </div>

                <img alt="direction-right" src="/icons/direction-right.svg" />
            </section>

            <DrawerCustom
                closable={true}
                onClose={() => setIsOpen(false)}
                open={isOpen}
                placement="bottom"
                height={"70%"}
                key="orderNote"
                className="rounded-tl-3xl rounded-tr-3xl"
                footer={
                    <Button
                        onClick={() => setIsOpen(false)}
                        className={cn(
                            "h-10 rounded-full flex justify-center items-center w-full bg-primary border-none outline-none",
                            "font-sans font-semibold text-base !text-neutral-0",
                        )}
                    >
                        {t("common.save")}
                    </Button>
                }
                closeIcon={
                    <div
                        className={cn(
                            "w-16 h-2 bg-neutral-10 rounded-md absolute",
                            "left-1/2 translate-x-[-50%]",
                            "hover:bg-neutral-20 transition",
                        )}
                    ></div>
                }
            >
                <div className="flex gap-2 items-center my-2">
                    <img alt="note" src="/icons/notes.svg" />
                    <p className="text-base text-neutral-60 font-semibold font-sans">
                        {t("order.note_label")}
                    </p>
                </div>
                <TextArea
                    className="border-none outline-0"
                    style={{
                        height: 120,
                        resize: "none",
                    }}
                    onChange={(e: any) => setNote(e?.target?.value)}
                    placeholder={t("order.note_desc")}
                    value={note}
                />
            </DrawerCustom>
        </>
    );
};
