import { NProduct } from "../../interfaces";
import Slider from "react-slick";
import { Image, Spin } from "antd";
import { cn } from "@utils/tailwind-marge";
import { formatPriceBaseOnCurrency, getPublicMediaUrl } from "../../utils";
import { FALLBACK_IMAGE_PATH } from "../../common/constant";
import { ProductService } from "../../services";
import { useEffect, useState } from "react";
import { DEFAULT_CURRENCY_CONFIG } from "../../constants";
import { useAppSelector } from "../../redux/store";
import { settingSelector } from "../../redux/slices";
import { useTranslation } from "react-i18next";
import { ProductConfigTypeEnum } from "../../enums/product-config-type.enum";

type Props = {
    title: string;
    product: NProduct.IProduct;
    productConfigType: ProductConfigTypeEnum;
    resetProductId?: (productId: string) => void;
};

const productService = ProductService.getInstance();

export const ConfigProduct: React.FC<Props> = (props: Props) => {
    const { title, product, productConfigType, resetProductId } = props;
    const { t } = useTranslation("common");

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        variableWidth: true,
        speed: 500,
        slidesToScroll: 2,
    };
    const { currencyConfig } = useAppSelector(settingSelector);
    let currency = currencyConfig ?? DEFAULT_CURRENCY_CONFIG;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<NProduct.IProduct[]>([]);

    useEffect(() => {
        if (product?.id) {
            getConfigProducts(product.id);
        }
    }, [product]);

    const getConfigProducts = async (productId: string) => {
        if (!productId) {
            return;
        }

        setIsLoading(true);
        const request = {
            productConfigType,
        };

        const result = await productService.getConfigProductsByProductId(
            request,
            productId,
        );

        if (!result) {
            setIsLoading(false);
        }
        setProducts(result);
        setIsLoading(false);
    };

    const onClickProduct = (product: NProduct.IProduct) => {
        resetProductId && resetProductId(product.id);
    };
    return (
        <Spin spinning={isLoading}>
            <div className="p-4">
                <p className="text-base font-semibold">{title}</p>
                <Slider {...settings}>
                    {products?.map((product: NProduct.IProduct) => (
                        <div
                            key={product.id}
                            className={
                                "font-normal px-4 py-2 min-w-24 cursor-pointer rounded-md transition"
                            }
                            onClick={() => onClickProduct(product)}
                        >
                            <div className="mb-2">
                                <Image
                                    className="object-contain rounded-lg"
                                    alt="product-img"
                                    src={getPublicMediaUrl(
                                        product?.photo ?? "",
                                    )}
                                    fallback={getPublicMediaUrl(
                                        FALLBACK_IMAGE_PATH,
                                    )}
                                    width={152}
                                    height={108}
                                    preview={false}
                                />
                            </div>
                            <div className="text-sm font-semibold">
                                <p>{product.name}</p>
                                <p>
                                    {formatPriceBaseOnCurrency(
                                        product.price,
                                        currency?.config,
                                    )}
                                </p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </Spin>
    );
};
