import { NProduct } from "@interfaces/index";
import { FinalPriceTemplate } from "@templates/discounted-price";
import { Image } from "antd";
import { FALLBACK_IMAGE } from "src/common/constant";
type Props = {
    product: NProduct.IProduct;
    hidePhoto?: boolean;
};

export const ProductInfo: React.FC<Props> = ({ product, hidePhoto }: Props) => {
    const { name, description, discount, photo, price } = product;
    return (
        <section className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
                <div className="flex justify-between w-full">
                    <p className="text-base text-neutral-60 font-semibold">
                        {name}
                    </p>

                    <FinalPriceTemplate
                        productPrice={price}
                        discountedPrice={discount?.price}
                        direction={"horizontal"}
                    />
                </div>

                {description && (
                    <p className="text-xs font-normal text-neutral-40">
                        {description}
                    </p>
                )}
            </div>
            {!hidePhoto && (
                <Image
                    className="rounded-lg"
                    src={photo ? photo : FALLBACK_IMAGE}
                    fallback={FALLBACK_IMAGE}
                    preview={false}
                />
            )}
        </section>
    );
};
