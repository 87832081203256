import { cn } from "@utils/tailwind-marge";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectStarDrawer, getStarCount } from "./select-start-drawer";
import { StarFilled } from "@ant-design/icons";
import { IProductRatingSummary } from "@interfaces/market/ratings.interface";

export type RatingFilterValue = {
    hasImage?: boolean;
    star?: number;
};

type Props = {
    summaryInfo: IProductRatingSummary;
    filter: RatingFilterValue;
    setFilter: (filter: RatingFilterValue) => void;
};

export const ProductRatingFilter = ({
    summaryInfo,
    filter,
    setFilter,
}: Props) => {
    const { t } = useTranslation("common");

    const { hasFileCount, totalRecord } = summaryInfo;

    const { hasImage, star } = filter;

    const [isOpenSelectStarDrawer, setIsOpenSelectStarDrawer] =
        useState<boolean>();

    const isViewAll = !hasImage && !star;

    return (
        <section className="w-full flex gap-2 justify-between">
            <FilterItem onClick={() => setFilter({})} active={isViewAll}>{`${t(
                "rating.all",
            )} (${totalRecord})`}</FilterItem>

            <FilterItem
                onClick={() => setFilter({ hasImage: true })}
                active={!!hasImage}
            >{`${t("rating.has_image")} (${hasFileCount})`}</FilterItem>

            <FilterItem
                active={!!star}
                onClick={() => {
                    setIsOpenSelectStarDrawer(true);
                }}
                className="flex items-center justify-center"
            >
                <div className="flex gap-2 w-fit">
                    <p>
                        {star} {t("rating.star")}
                    </p>
                    <StarFilled className="text-primary" rev={undefined} />(
                    {getStarCount(star ?? 0, summaryInfo)})
                </div>
                <SelectStarDrawer
                    summaryInfo={summaryInfo}
                    onChange={(star) => {
                        setFilter({
                            star,
                        });
                        setIsOpenSelectStarDrawer(false);
                    }}
                    setIsOpenSelectStarDrawer={setIsOpenSelectStarDrawer}
                    isOpenSelectStarDrawer={isOpenSelectStarDrawer}
                />
            </FilterItem>
        </section>
    );
};

type FilterItemProps = {
    active?: boolean;
    children?: any;
    onClick?: () => void;
    className?: string;
};
const FilterItem = ({
    active,
    children,
    onClick,
    className,
}: FilterItemProps) => {
    return (
        <div
            onClick={onClick}
            className={cn(
                "w-1/3 px-4 py-2 bg-white border-solid border text-center cursor-pointer rounded-lg",
                active && "border-primary text-primary font-semibold",
                className,
            )}
        >
            {children}
        </div>
    );
};
