import { ItemVisibilityEnum } from "../../../../enums/items-visibility.enum";
import { NProduct } from "../../../../interfaces";

export type UiProductAttributeType = {
    name: string;
    isSelected?: boolean;
    visibility?: ItemVisibilityEnum;
};

export const getAttributeGroup = (
    defaultId: string | undefined,
    attributes: NProduct.IProductAttribute[],
) => {
    const attrGroup: Record<string, UiProductAttributeType[]> = {};
    if (attributes.length) {
        const defaultAttribute = attributes.find(
            (item) => item.id === defaultId,
        )?.size;
        attributes.forEach((attribute) => {
            const size = attribute?.size;
            const groups = size?.match(/\[(.*?)\]/gm) ?? [];

            groups?.forEach((group) => {
                const value = group
                    .replace("[", "")
                    .replace("]", "")
                    .split("-");
                const title = value[0].trim();
                const attr = value[1].trim();
                const isSelected = defaultAttribute?.includes(group);
                if (title) {
                    if (!attrGroup[`${title}`]) {
                        attrGroup[`${title}`] = [];
                    }
                    if (
                        !attrGroup[`${title}`].some((att) => att.name === attr)
                    ) {
                        attrGroup[`${title}`].push({
                            name: attr.trim(),
                            isSelected: isSelected,
                            visibility: ItemVisibilityEnum.VISIBLE,
                        });
                    }
                }
            });
        });
    }
    return attrGroup;
};
