import { ILatLng, ShippingSettingService } from "@services/api";
import useSWR from "swr";

export interface IEstimateShippingFee extends ILatLng {
    address?: string;
}

const shippingSettingApi = ShippingSettingService.getInstance();

export const useShippingSettings = (shopId?: string) => {
    const fetchShippingSettings = useSWR(
        shopId ? `shipping-settings-${shopId}` : null,
        () => shippingSettingApi.getShopShippingSettings(shopId ?? ""),
        { revalidateOnFocus: false },
    );

    const fetchEstimateShippingFee = ({
        lat,
        lng,
        address,
    }: Partial<IEstimateShippingFee>) =>
        useSWR(
            shopId && (address || (lat && lng))
                ? `estimate-shipping-fee-${shopId}-${lat}-${lng}-${address}`
                : null,
            () =>
                shippingSettingApi.getEstimateShippingFee({
                    shopId: shopId ?? "",
                    lat,
                    lng,
                    address,
                }),
            { revalidateOnFocus: false },
        );

    return { fetchShippingSettings, fetchEstimateShippingFee };
};
