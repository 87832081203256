export enum PaymentMethodEnum {
    CASH = "cash",
    CREDIT = "credit",
    PAY_LATER = "pay_later",
    GPAY = "gpay",
    DEDUCT = "deduct",
    TRADITIONAL_TERMINAL = "traditional_terminal",
    SMART_PAY = "smart_pay",
}

export enum CreditProviderEnum {
    GPAY = "gpay",
    ONEPAY = "onepay",
    SMARTPAY = "smart_pay",
    BAOKIM = "baokim",
}
