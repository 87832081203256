import { MarketLocationService } from "@services/api";
import useSWR from "swr";

const locationApi = MarketLocationService.getInstance();

export const useMarketLocations = () => {
    const fetchCities = useSWR(
        ["market-cities"],
        () => locationApi.getCities(),
        { revalidateOnFocus: false },
    );

    const fetchDistricts = (cityId?: string) =>
        useSWR(
            cityId ? `market-districts-${cityId}` : null,
            () => locationApi.getDistricts(cityId ?? ""),
            { revalidateOnFocus: false },
        );

    const fetchWards = (districtId?: string) =>
        useSWR(
            districtId ? `market-wards-${districtId}` : null,
            () => locationApi.getWards(districtId ?? ""),
            { revalidateOnFocus: false },
        );

    return { fetchCities, fetchDistricts, fetchWards };
};
