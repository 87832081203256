import { MarketService } from "@services/api/market.service";
import useSWR from "swr";

const marketApi = MarketService.getInstance();

export const useBannerSetting = () => {
    const fetchBannerSetting = useSWR(
        ["banner-setting"],
        () => marketApi.getBannerSetting(),
        { revalidateOnFocus: false },
    );

    return { fetchBannerSetting };
};
