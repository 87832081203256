import s from "./gift-card-balance.module.scss";

import { IGiftCardCode } from "@interfaces/components/gift-card-code";
import { cartSelector } from "@redux/slices/cartSlice";
import { settingSelector } from "@redux/slices/settingSlice";
import { useAppDispatch, useAppSelector } from "@redux/store";
import {
    formatPriceBaseOnCurrency,
    formatValuePrice,
    toCentPrice,
} from "@utils/format-price";
import { validateBalanceUse } from "@utils/validator";
import { Button, Form, Image, Spin } from "antd";
import { useTranslation } from "next-i18next";
import { FC, useEffect } from "react";
import { FORMAT_ll } from "../../../../common/constant";
import { formatDateTime } from "../../../../utils";
import { DrawerCustom } from "../../custom-drawer";

import { addGiftCardToCart } from "@redux/slices/cartSlice";
import {
    addToGiftCart,
    giftCartSelector,
} from "../../../../redux/slices/giftCardSlice";
import { GiftCardCodeGraphQL } from "../../../../services/apollo/gift-card-code.query";
import { NumberInput } from "../../number-input";

import { useCheckoutContext } from "../../checkout/checkout.context";

type GiftCardBalanceDrawerProps = {
    isShow: boolean;
    isOrderDetail: boolean;
    giftCardCode: IGiftCardCode.Request;
    handleCancel: () => void;
    balance?: number;
    isMobile: boolean;
};

const giftCardCodeQuery = new GiftCardCodeGraphQL();

export const GiftCardBalanceDrawer: FC<GiftCardBalanceDrawerProps> = ({
    isShow,
    giftCardCode,
    isOrderDetail,
    handleCancel,
    balance,
    isMobile,
}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation("common");

    const dispatch = useAppDispatch();
    const cart = useAppSelector(cartSelector);
    const giftCard = useAppSelector(giftCartSelector);
    const total = isOrderDetail
        ? (giftCard.grandTotal || 0) +
          (giftCard?.tipAmount || 0) -
          (giftCard?.giftCardAmount || 0)
        : (cart.grandTotal || 0) +
          (cart?.tipAmount || 0) -
          (cart?.giftCardAmount || 0);

    const { currencyConfig } = useAppSelector(settingSelector);

    const { data, loading, error, refetch } =
        giftCardCodeQuery.checkGcCodeValid();

    const handleSubmit = async () => {
        form.validateFields().then(() => {
            refetch({ variables: { id: giftCardCode.id } });
        });
    };

    useEffect(() => {
        if (balance) {
            form.setFieldValue("balance", balance);
        }
    }, [balance]);

    useEffect(() => {
        if (total === 0) {
            form.setFieldValue("balance", 0);
        } else {
            if (total > giftCardCode.remainingBalance) {
                form.setFieldValue(
                    "balance",
                    formatValuePrice(giftCardCode.remainingBalance),
                );
            } else {
                form.setFieldValue("balance", formatValuePrice(total));
            }
        }
    }, [giftCardCode]);

    useEffect(() => {
        if (data && !loading && !error) {
            const balance = form.getFieldValue("balance");

            if (!isOrderDetail) {
                dispatch(
                    addGiftCardToCart({
                        giftCardCodeId: giftCardCode.id,
                        balance: toCentPrice(balance),
                        giftCardName: giftCardCode?.giftCard?.name || "",
                    }),
                );
            } else {
                dispatch(
                    addToGiftCart({
                        giftCardCodeId: giftCardCode.id,
                        balance: toCentPrice(balance),
                        giftCardName: giftCardCode?.giftCard?.name || "",
                    }),
                );
            }

            handleCancel();
        }
    }, [data]);

    const Footer = () => (
        <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit}
            className="h-auto p-4 text-base font-bold"
        >
            {t("common.apply")}
        </Button>
    );

    return (
        <DrawerCustom
            key="gift-card-balance"
            title={t("order.gift_card")}
            getContainer={false}
            open={isShow}
            onClose={handleCancel}
            footer={<Footer />}
            height={610}
            placement={isMobile ? "bottom" : "right"}
            className={s.giftCardBalanceDrawer}
            closeIcon={<Image src="/icons/bt_close.svg" preview={false} />}
        >
            {loading ? (
                <div className="flex items-center justify-center">
                    <Spin spinning className="w-full" />
                </div>
            ) : (
                <>
                    <p className={`${s.textContent} mb-3`}>
                        {t("gift_card.select_price")}
                    </p>
                    <div className="flex items-center justify-between mt-5">
                        <p className={s.textName}>
                            {giftCardCode.giftCard.name}
                        </p>
                        <p className={s.textBalance}>
                            {formatPriceBaseOnCurrency(
                                giftCardCode.remainingBalance,
                                currencyConfig?.config,
                            )}
                        </p>
                    </div>

                    {giftCardCode.expiredTime && (
                        <p className="flex items-center mb-4">
                            <span className={`${s.expiredDateText} mr-1`}>
                                {t("gift_card.expired_date")}{" "}
                            </span>
                            <span className={s.expiredDate}>
                                {formatDateTime(
                                    giftCardCode.expiredTime,
                                    FORMAT_ll,
                                )}
                            </span>
                        </p>
                    )}

                    <Form
                        form={form}
                        size="large"
                        layout="vertical"
                        className="mt-5"
                    >
                        <Form.Item
                            name="balance"
                            className="mb-12"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: t("gift_card.balance_required"),
                                },
                                {
                                    validator: async (_, value) => {
                                        if (
                                            !value ||
                                            validateBalanceUse(
                                                Number(value),
                                                giftCardCode.remainingBalance,
                                                isOrderDetail
                                                    ? giftCard.grandTotal +
                                                          (giftCard?.tipAmount ||
                                                              0)
                                                    : (cart?.grandTotal || 0) +
                                                          (cart?.tipAmount ||
                                                              0),
                                                isOrderDetail
                                                    ? giftCard?.giftCardAmount ||
                                                          0
                                                    : cart?.giftCardAmount || 0,
                                                balance,
                                            )
                                        )
                                            return Promise.resolve();
                                        return Promise.reject(
                                            t("gift_card.balance_valid"),
                                        );
                                    },
                                },
                            ]}
                        >
                            <NumberInput
                                placeholder={t("gift_card.balance_placeholder")}
                                isPrice={true}
                                currency={currencyConfig}
                                defaultValue={
                                    balance
                                        ? formatValuePrice(
                                              balance * 100,
                                              currencyConfig?.config,
                                          )
                                        : total > giftCardCode.remainingBalance
                                        ? formatValuePrice(
                                              giftCardCode.remainingBalance,
                                              currencyConfig?.config,
                                          )
                                        : total
                                        ? formatValuePrice(
                                              total,
                                              currencyConfig?.config,
                                          )
                                        : 0
                                }
                            />
                        </Form.Item>
                    </Form>
                </>
            )}
        </DrawerCustom>
    );
};
