export const QRIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.66675 4.125C5.63287 4.125 4.79175 4.96613 4.79175 6V9C4.79175 10.0339 5.63287 10.875 6.66675 10.875H9.66675C10.7006 10.875 11.5417 10.0339 11.5417 9V6C11.5417 4.96613 10.7006 4.125 9.66675 4.125H6.66675ZM15.2917 4.125C14.2579 4.125 13.4167 4.96613 13.4167 6V9C13.4167 10.0339 14.2579 10.875 15.2917 10.875H18.2917C19.3256 10.875 20.1667 10.0339 20.1667 9V6C20.1667 4.96613 19.3256 4.125 18.2917 4.125H15.2917ZM6.66675 5.625H9.66675C9.87375 5.625 10.0417 5.79338 10.0417 6V9C10.0417 9.20662 9.87375 9.375 9.66675 9.375H6.66675C6.45975 9.375 6.29175 9.20662 6.29175 9V6C6.29175 5.79338 6.45975 5.625 6.66675 5.625ZM15.2917 5.625H18.2917C18.4987 5.625 18.6667 5.79338 18.6667 6V9C18.6667 9.20662 18.4987 9.375 18.2917 9.375H15.2917C15.0847 9.375 14.9167 9.20662 14.9167 9V6C14.9167 5.79338 15.0847 5.625 15.2917 5.625ZM7.41675 6.75V8.25H8.91675V6.75H7.41675ZM16.0417 6.75V8.25H17.5417V6.75H16.0417ZM6.66675 12.75C5.63287 12.75 4.79175 13.5911 4.79175 14.625V17.625C4.79175 18.6589 5.63287 19.5 6.66675 19.5H9.66675C10.7006 19.5 11.5417 18.6589 11.5417 17.625V14.625C11.5417 13.5911 10.7006 12.75 9.66675 12.75H6.66675ZM13.4167 12.75V15H15.6667V12.75H13.4167ZM15.6667 15V17.25H17.9167V15H15.6667ZM17.9167 15H20.1667V12.75H17.9167V15ZM17.9167 17.25V19.5H20.1667V17.25H17.9167ZM15.6667 17.25H13.4167V19.5H15.6667V17.25ZM6.66675 14.25H9.66675C9.87375 14.25 10.0417 14.4184 10.0417 14.625V17.625C10.0417 17.8316 9.87375 18 9.66675 18H6.66675C6.45975 18 6.29175 17.8316 6.29175 17.625V14.625C6.29175 14.4184 6.45975 14.25 6.66675 14.25ZM7.41675 15.375V16.875H8.91675V15.375H7.41675Z"
                fill="#E4E6EA"
            />
        </svg>
    );
};
