import s from "./clean-up-cart.module.scss";

import { OrderItemTypeEnum } from "@enums/order-item-type.enum";
import { cartSelector, setOrderItemType } from "@redux/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { Button } from "antd";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { DrawerCustom } from "../custom-drawer";

type CleanUpCartProps = {
    isOpen: boolean;
    orderItemType: OrderItemTypeEnum;
    handleAddToCart: () => void;
    handleClose: () => void;
    isMobile: boolean;
};

export const CleanUpCart: FC<CleanUpCartProps> = ({
    isOpen,
    orderItemType,
    handleAddToCart,
    handleClose,
    isMobile,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const cart = useAppSelector(cartSelector);

    const handleCleanUpCart = () => {
        if (cart) {
            dispatch(setOrderItemType(orderItemType));
            handleAddToCart();
        }
    };

    const Footer = () => (
        <div className="p-3 mt-4 flex items-center justify-between">
            <Button
                className="btn-clean-up btn-cancel"
                onClick={() => {
                    handleClose();
                }}
            >
                {t("common.cancel")}
            </Button>
            <Button
                className="btn-clean-up ml-4 btn-confirm"
                onClick={() => {
                    handleCleanUpCart();
                    handleClose();
                }}
            >
                {t("common.confirm")}
            </Button>
        </div>
    );

    return (
        <DrawerCustom
            title={t("common.notification")}
            placement={isMobile ? "bottom" : "right"}
            closable={true}
            open={isOpen}
            onClose={handleClose}
            className={`${!isMobile && s.cleanUpCartDesktop} ${s.cleanUpCart}`}
            closeIcon={<></>}
            footer={<Footer />}
            height={250}
            key="clean-up-cart"
        >
            <section className="mt-2">
                <p className="cleanUpText">
                    {t("order.clean_up_cart_content")}
                </p>
            </section>
        </DrawerCustom>
    );
};
