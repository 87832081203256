import s from "./gift-card-choose.module.scss";

import { IGiftCardCode } from "@interfaces/components/gift-card-code";
import { cartSelector } from "@redux/slices/cartSlice";
import { settingSelector } from "@redux/slices/settingSlice";
import { useAppSelector } from "@redux/store";
import { GiftCardCodeGraphQL } from "@services/apollo/gift-card-code.query";
import { formatPriceBaseOnCurrency, toDollarPrice } from "@utils/format-price";
import { getPublicMediaUrl } from "@utils/resource";
import { Button, Image } from "antd";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import { giftCartSelector } from "../../../redux/slices/giftCardSlice";
import { DrawerCustom } from "../custom-drawer";
import { GiftCardNotFound } from "../gift-card-not-found";
import { GiftCardBalanceDrawer } from "./gift-card-balance";
import { GiftCardCodeDrawer } from "./gift-card-code";
import { GiftCardListDrawer } from "./gift-card-list";

type GiftCardChooseDrawerProps = {
    isShow: boolean;
    isCustomer: boolean;
    isOrderDetail: boolean;
    handleCancel: () => void;
    isMobile: boolean;
};

const giftCardCodeQuery = new GiftCardCodeGraphQL();

export const GiftCardChooseDrawer: FC<GiftCardChooseDrawerProps> = ({
    isShow,
    isCustomer,
    isOrderDetail,
    handleCancel,
    isMobile,
}) => {
    const { t } = useTranslation("common");
    const cart = useAppSelector(cartSelector);
    const giftCard = useAppSelector(giftCartSelector);
    const { currencyConfig } = useAppSelector(settingSelector);

    const [balanceUse, setBalanceUse] = useState<number>();
    const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
    const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);
    const [giftCardUseIds, setGiftCardUseIds] = useState<string[]>([]);
    const [isEnterBalance, setIsEnterBalance] = useState<boolean>(false);
    const [giftCardCode, setGiftCardCode] = useState<IGiftCardCode.Request>();
    const [giftCardCodes, setGiftCardCodes] = useState<IGiftCardCode.Request[]>(
        [],
    );

    const { data, loading, error, refetch } = giftCardCodeQuery.getByIds();

    useEffect(() => {
        const giftCardGroups = isOrderDetail
            ? giftCard?.giftCardGroups
            : cart?.giftCardGroups;

        const gcIds = giftCardGroups?.map((gc) => gc.giftCardCodeId) || [];

        if (gcIds?.length) {
            setGiftCardUseIds(gcIds);
            refetch({ variables: { ids: gcIds } });
        } else {
            setGiftCardUseIds([]);
            setGiftCardCodes([]);
        }

        setIsUpdateData(true);
    }, [cart, giftCard, isOrderDetail, isUpdateData]);

    useEffect(() => {
        if (!loading && !!data?.length && !error) {
            setGiftCardCodes(data);
        } else {
            setGiftCardCodes([]);
        }
    }, [data]);

    const handleSelectGcCode = (value: IGiftCardCode.Request) => {
        setGiftCardCode(value);
        setIsEnterBalance(true);
    };

    const getBalanceUse = (id: string): number => {
        const giftCardGroups = isOrderDetail
            ? giftCard?.giftCardGroups
            : cart?.giftCardGroups;

        const balance =
            giftCardGroups?.find((gc) => gc.giftCardCodeId === id)?.balance ||
            0;

        return balance;
    };

    const handleChangeBalanceUse = (value: IGiftCardCode.Request) => {
        const giftCardGroups = isOrderDetail
            ? giftCard?.giftCardGroups
            : cart?.giftCardGroups;

        const balance =
            giftCardGroups?.find((gc) => gc.giftCardCodeId === value.id)
                ?.balance || 0;

        setBalanceUse(toDollarPrice(balance));
        setGiftCardCode(value);
        setIsEnterBalance(true);
    };

    const Footer = () => (
        <Button
            type="primary"
            htmlType="submit"
            className={`h-auto p-4 text-base font-bold`}
            onClick={() => setIsShowDrawer(true)}
        >
            {t("gift_card.add")}
        </Button>
    );

    return (
        <>
            <DrawerCustom
                key="gift-card"
                title={t("order.gift_card")}
                getContainer={false}
                open={isShow}
                onClose={handleCancel}
                footer={<Footer />}
                height={610}
                placement={isMobile ? "bottom" : "right"}
                className={s.giftCardChooseDrawer}
                closeIcon={<Image src="/icons/bt_close.svg" preview={false} />}
            >
                {giftCardCodes?.length ? (
                    <>
                        {giftCardCodes.map((code) => (
                            <div className={`${s.giftCardCode}`} key={code.id}>
                                <img
                                    src={getPublicMediaUrl(
                                        code?.giftCard?.image,
                                    )}
                                    alt="gift-card-code"
                                    className={s.img}
                                />
                                <div className="ml-6 w-full">
                                    <h5 className={s.title}>
                                        {code?.giftCard?.name}
                                    </h5>
                                    <div className="flex justify-between items-end mt-1 w-full">
                                        <div className={s.balanceBlock}>
                                            <div className={s.remainingBalance}>
                                                {formatPriceBaseOnCurrency(
                                                    code.remainingBalance,
                                                    currencyConfig?.config,
                                                )}
                                            </div>
                                            <div className={s.balanceUse}>
                                                {t(
                                                    "gift_card.balance_code_use",
                                                    {
                                                        balance:
                                                            formatPriceBaseOnCurrency(
                                                                getBalanceUse(
                                                                    code.id,
                                                                ),
                                                                currencyConfig?.config,
                                                            ),
                                                    },
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="flex items-center cursor-pointer"
                                            onClick={() =>
                                                handleChangeBalanceUse(code)
                                            }
                                        >
                                            <span className={s.edit}>
                                                {t("common.edit")}
                                            </span>
                                            <img
                                                src="/icons/arrow-right-icon.svg"
                                                alt="arrow-right"
                                                className="ml-1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <GiftCardNotFound
                        isModal
                        textNoResult={t("gift_card.no_gc_use")}
                        textDescription={t("gift_card.no_gc_use_detail")}
                        handleBack={() => setIsShowDrawer(true)}
                        isHideBackBtn
                    />
                )}
            </DrawerCustom>

            {isCustomer ? (
                <GiftCardListDrawer
                    isShow={isShowDrawer}
                    giftCardUseIds={giftCardUseIds}
                    handleCancel={() => setIsShowDrawer(false)}
                    handleOk={handleSelectGcCode}
                    isMobile={isMobile}
                />
            ) : (
                <GiftCardCodeDrawer
                    isShow={isShowDrawer}
                    giftCardUseIds={giftCardUseIds}
                    handleCancel={() => setIsShowDrawer(false)}
                    handleOk={handleSelectGcCode}
                    isMobile={isMobile}
                />
            )}

            {isEnterBalance && giftCardCode && (
                <GiftCardBalanceDrawer
                    isShow={isEnterBalance}
                    giftCardCode={giftCardCode}
                    isOrderDetail={isOrderDetail}
                    handleCancel={() => {
                        setIsEnterBalance(false);
                        setIsShowDrawer(false);
                        setIsUpdateData(false);
                        setBalanceUse(0);
                    }}
                    balance={balanceUse}
                    isMobile={isMobile}
                />
            )}
        </>
    );
};
