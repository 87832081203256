export const MasterCardIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_545_2095)">
                <path
                    d="M16.5833 4.75C14.9948 4.75 13.5293 5.2695 12.3333 6.139C11.1373 5.2695 9.67175 4.75 8.08325 4.75C4.08575 4.75 0.833252 8.0025 0.833252 12C0.833252 15.9975 4.08575 19.25 8.08325 19.25C9.67175 19.25 11.1373 18.7305 12.3333 17.861C13.5293 18.7305 14.9948 19.25 16.5833 19.25C20.5808 19.25 23.8333 15.9975 23.8333 12C23.8333 8.0025 20.5808 4.75 16.5833 4.75ZM12.3333 15.07C11.7073 14.2055 11.3333 13.147 11.3333 12C11.3333 10.853 11.7073 9.7945 12.3333 8.93C12.9593 9.7945 13.3333 10.853 13.3333 12C13.3333 13.147 12.9593 14.2055 12.3333 15.07ZM16.5833 17.25C15.5603 17.25 14.6078 16.9515 13.7998 16.443C14.7568 15.2145 15.3333 13.6745 15.3333 12C15.3333 10.3255 14.7568 8.7855 13.7998 7.557C14.6078 7.049 15.5603 6.75 16.5833 6.75C19.4783 6.75 21.8333 9.105 21.8333 12C21.8333 14.895 19.4783 17.25 16.5833 17.25Z"
                    fill="#E4E6EA"
                />
            </g>
            <defs>
                <clipPath id="clip0_545_2095">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.333252)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
