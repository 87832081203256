import { cn } from "@utils/tailwind-marge";
import { Divider, Rate } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ProductRatingDrawer } from "./product-rating-drawer";
import { ProductRatingItem } from "./product-rating-item";
import { useRatings } from "@services/api-hook/useRatings";
import React from "react";

type Props = {
    productId: string;
};

export const ProductRating: React.FC<Props> = ({ productId }) => {
    const { t } = useTranslation("common");
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { fetchProductRatingInfo, fetchProductRatings } = useRatings();

    const { data, isLoading } = fetchProductRatings({
        productId,
        pageIndex: 1,
        pageSize: 3,
    });

    const {
        data: summaryInfo = {
            fiveStarCount: 0,
            fourStarCount: 0,
            hasFileCount: 0,
            oneStarCount: 0,
            threeStarCount: 0,
            twoStarCount: 0,
            totalRecord: 0,
            averageRate: 0,
        },
    } = fetchProductRatingInfo(productId);

    const { data: productRatings, total } = data ?? { data: [], total: 0 };

    return (
        <>
            <section id="product-rating">
                {total && total > 0 ? (
                    <>
                        <article
                            onClick={() => setIsOpen(true)}
                            className={cn(
                                "flex justify-between items-center gap-2 p-4",
                                "hover:bg-neutral-5 cursor-pointer transition",
                            )}
                        >
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col">
                                    <p className="text-base text-neutral-60 font-semibold font-sans">
                                        {t("rating.product_rating")}
                                    </p>
                                    <div className="flex gap-2 items-center">
                                        <Rate
                                            allowHalf
                                            value={
                                                Number(
                                                    summaryInfo.averageRate,
                                                ) ?? 0
                                            }
                                        />
                                        <p>
                                            <b>{summaryInfo.averageRate}</b>/5
                                        </p>
                                        <p>
                                            (
                                            {t("rating.rating_count", {
                                                count: Number(
                                                    summaryInfo.totalRecord,
                                                ),
                                            })}
                                            )
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <img
                                alt="direction-right"
                                src="/icons/direction-right.svg"
                            />
                        </article>

                        <hr />

                        <article className="flex flex-col gap-4 p-4">
                            {productRatings?.map((item) => (
                                <React.Fragment key={item.id}>
                                    <ProductRatingItem item={item} />
                                    <Divider className="m-0" />
                                </React.Fragment>
                            ))}
                        </article>

                        <div
                            onClick={() => setIsOpen(true)}
                            className="h-14 w-full mb-4 rounded-none bg-white text-primary border-none font-semibold flex items-center justify-center cursor-pointer hover:bg-neutral-5"
                        >
                            {`${t("rating.view_all")} (${
                                summaryInfo.totalRecord
                            })`}
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10 7L14 12L10 17"
                                    stroke="#ee761b"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </>
                ) : (
                    <p className="text-sm text-neutral-60 font-normal font-sans p-4">
                        <i>{t("rating.no_rating")}</i>
                    </p>
                )}
            </section>

            <ProductRatingDrawer
                summaryInfo={summaryInfo}
                productId={productId}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
            />
        </>
    );
};
