import s from "./product-in-cart.module.scss";
import { Action, ActionProps } from "./action";

type Props = ActionProps & {
    isFFood?: boolean;
    cartItemId?: string;
    quantity: number;
    setQuantity: (quantity: number) => void;
};

export const Footer = (props: Props) => {
    const { quantity, setQuantity, cartItemId, isFFood } = props;
    return (
        <>
            <div className="flex justify-between items-center gap-3">
                <div className={s.quantityInput}>
                    <span
                        className="p-3 cursor-pointer"
                        onClick={() => {
                            if (quantity) {
                                if (!cartItemId && quantity === 1) {
                                    return;
                                }

                                setQuantity(quantity - 1);
                            }
                        }}
                    >
                        <img
                            className="w-3 h-3 min-w-3 min-h-3"
                            src={`/icons/${
                                isFFood
                                    ? "subtract.svg"
                                    : "combined-shape-sub.svg"
                            }`}
                            alt="-"
                        />
                    </span>
                    <p>{quantity}</p>
                    <span
                        className="p-3 cursor-pointer"
                        onClick={() => {
                            setQuantity(quantity + 1);
                        }}
                    >
                        <img
                            className="w-3 h-3 min-w-3 min-h-3"
                            src={`/icons/${
                                isFFood ? "add.svg" : "combined-shape.svg"
                            }`}
                            alt="+"
                        />
                    </span>
                </div>
                <Action {...props} />
            </div>
        </>
    );
};
