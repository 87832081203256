import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { getPublicMediaUrl } from "@utils/resource";

interface IImagesSlick {
    url: string;
    id: string;
}
interface IImageSlickProp {
    images: IImagesSlick[];
    defaultId?: string;
    onChange?: (id: string) => void;
}

export const ImagesSlick: React.FC<IImageSlickProp> = (props) => {
    const { images, defaultId, onChange } = props;

    const [nav1, setNav1] = useState<Slider | undefined>();
    const [nav2, setNav2] = useState<Slider | undefined>();
    let sliderRef1 = useRef<Slider | undefined>(undefined);
    let sliderRef2 = useRef<Slider | undefined>(undefined);
    useEffect(() => {
        setNav1(sliderRef1 as any);
        setNav2(sliderRef2 as any);
    }, []);

    useEffect(() => {
        const index = images.findIndex((item) => item.id === defaultId);
        if (index >= -1) {
            sliderRef1?.current?.slickGoTo(index);
        }
    }, [defaultId]);

    const onClickImage = (index: number) => {
        const image = images[index];
        onChange?.(image?.id);
    };

    return (
        <>
            {images?.length ? (
                <div className="slider-container">
                    <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider as any)}
                        className="mb-2"
                    >
                        {images.map((image) => (
                            <img src={getPublicMediaUrl(image?.url)} />
                        ))}
                    </Slider>
                    <Slider
                        asNavFor={nav1}
                        ref={(slider) => (sliderRef2 = slider as any)}
                        slidesToShow={5}
                        swipeToSlide={true}
                        focusOnSelect={true}
                    >
                        {images.map((image, index) => (
                            <div
                                className="p-1"
                                onClick={() => onClickImage(index)}
                            >
                                <img
                                    className="rounded-md"
                                    src={getPublicMediaUrl(image?.url)}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
