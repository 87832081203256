import {
    GetProductRatingsRequestDto,
    IUnratedOrderRequestDto,
} from "@interfaces/market/ratings.interface";
import { ILatLng } from "@services/api";
import { RatingService } from "@services/api/rating.service";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export interface IEstimateShippingFee extends ILatLng {
    address?: string;
}

const ratingApi = RatingService.getInstance();

export const useRatings = () => {
    const { t } = useTranslation("common");

    const fetchUnratedOrders = (request: IUnratedOrderRequestDto) =>
        useSWR(
            ["rating", "unrated-orders", request],
            () => ratingApi.getUnratedOrders(request),
            {
                revalidateOnFocus: false,
            },
        );

    const fetchByOrderId = (orderId?: string) =>
        useSWR(
            orderId ? ["rating", "getByOrderId", orderId] : null,
            () => ratingApi.getOrderRatingByOrderId(orderId ?? ""),
            {
                revalidateOnFocus: false,
            },
        );

    const fetchProductRatingInfo = (productId?: string) =>
        useSWR(
            productId ? ["rating", "fetchProductRatingInfo", productId] : null,
            () => ratingApi.getProductRatingInfo(productId ?? ""),
            {
                revalidateOnFocus: false,
            },
        );

    const fetchProductRatings = (request: GetProductRatingsRequestDto) =>
        useSWR(
            ["rating", "getProductRatings", request],
            () => ratingApi.getProductRatings(request),
            {
                revalidateOnFocus: false,
            },
        );

    const createRating = useSWRMutation(
        ["rating", "create"],
        (_, { arg }: { arg: FormData }) => {
            return ratingApi
                .createRatingOrder(arg)
                .then((response) => {
                    if (response) {
                        ratingApi.createSuccessNotification(
                            t("rating.create_success"),
                        );
                        return true;
                    } else {
                        ratingApi.createErrorNotification(
                            t("rating.create_failed"),
                        );
                        return false;
                    }
                })
                .catch(() => {
                    ratingApi.createErrorNotification(
                        t("rating.create_failed"),
                    );
                    return false;
                });
        },
    );

    return {
        fetchUnratedOrders,
        fetchByOrderId,
        createRating,
        fetchProductRatingInfo,
        fetchProductRatings,
    };
};
