export const MomoIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_545_2104)">
                <path
                    d="M12.6667 24C19.2942 24 24.6667 18.6274 24.6667 12C24.6667 5.37258 19.2942 0 12.6667 0C6.03933 0 0.666748 5.37258 0.666748 12C0.666748 18.6274 6.03933 24 12.6667 24Z"
                    fill="#E4E6EA"
                />
                <path
                    d="M16.7239 11.3571C18.4025 11.3571 19.7597 9.99999 19.7597 8.32142C19.7597 6.64285 18.4025 5.28571 16.7239 5.28571C15.0454 5.28571 13.6882 6.64285 13.6882 8.32142C13.6882 9.99999 15.0454 11.3571 16.7239 11.3571ZM16.7239 7.02856C17.4382 7.02856 18.0168 7.60713 18.0168 8.32142C18.0168 9.03571 17.4382 9.61428 16.7239 9.61428C16.0097 9.61428 15.4311 9.03571 15.4311 8.32142C15.4311 7.60713 16.0097 7.02856 16.7239 7.02856Z"
                    fill="#242526"
                />
                <path
                    d="M10.5382 5.28571C10.0239 5.28571 9.5525 5.45713 9.17393 5.74285C8.79535 5.45713 8.31678 5.28571 7.80964 5.28571C6.54535 5.28571 5.52393 6.30713 5.52393 7.56428V11.3571H7.26678V7.54285C7.26678 7.25713 7.49535 7.02856 7.78107 7.02856C8.06678 7.02856 8.29535 7.25713 8.29535 7.54285V11.3571H10.0382V7.54285C10.0382 7.25713 10.2668 7.02856 10.5525 7.02856C10.8382 7.02856 11.0668 7.25713 11.0668 7.54285V11.3571H12.8096V7.56428C12.8096 6.30713 11.7954 5.28571 10.5382 5.28571Z"
                    fill="#242526"
                />
                <path
                    d="M16.7239 12.6714C15.0454 12.6714 13.6882 14.0286 13.6882 15.7071C13.6882 17.3857 15.0454 18.7428 16.7239 18.7428C18.4025 18.7428 19.7597 17.3857 19.7597 15.7071C19.7597 14.0286 18.4025 12.6714 16.7239 12.6714ZM16.7239 17C16.0097 17 15.4311 16.4214 15.4311 15.7071C15.4311 14.9928 16.0097 14.4143 16.7239 14.4143C17.4382 14.4143 18.0168 14.9928 18.0168 15.7071C18.0168 16.4214 17.4382 17 16.7239 17Z"
                    fill="#242526"
                />
                <path
                    d="M10.5381 12.6714C10.0239 12.6714 9.55243 12.8428 9.17386 13.1286C8.79529 12.8428 8.31672 12.6714 7.80958 12.6714C6.55243 12.6714 5.53101 13.6928 5.53101 14.95V18.75H7.27386V14.9286C7.27386 14.6428 7.50243 14.4143 7.78815 14.4143C8.07386 14.4143 8.30243 14.6428 8.30243 14.9286V18.7428H10.0453V14.9286C10.0453 14.6428 10.2739 14.4143 10.5596 14.4143C10.8453 14.4143 11.0739 14.6428 11.0739 14.9286V18.7428H12.8096V14.9428C12.8096 13.6928 11.7953 12.6714 10.5381 12.6714Z"
                    fill="#242526"
                />
            </g>
            <defs>
                <clipPath id="clip0_545_2104">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.666748)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
