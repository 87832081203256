import { IItemRatingDto } from "@interfaces/market/ratings.interface";
import { getPublicMediaUrl } from "@utils/resource";
import { Image, Rate } from "antd";

type Props = {
    item?: IItemRatingDto;
};

export const ProductRatingItem = ({ item }: Props) => {
    return (
        <section className="flex flex-col gap-2">
            <span>
                <p className="font-semibold">{item?.customerName}</p>
                <Rate allowHalf value={item?.rate} />
            </span>

            <span>
                <p>{item?.title}</p>
                <p>{item?.description}</p>
            </span>

            <div className="flex gap-2 flex-wrap">
                {item?.files?.map(({ url }) => (
                    <Image
                        className="rounded-lg"
                        width={100}
                        height={100}
                        src={getPublicMediaUrl(url)}
                        alt={item?.metadata.productName}
                    />
                ))}
            </div>
        </section>
    );
};
