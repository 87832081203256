import BaseApi from "./baseApi.service";
import Response from "../../data-access/response/response";
import {
    GetProductRatingsRequestDto,
    IItemRatingDto,
    IMyRatingRequestDto,
    IOrderRatingDto,
    IProductRatingSummary,
    IUnratedOrderRequestDto,
} from "@interfaces/market/ratings.interface";
import { NOrderType } from "@interfaces/components";
import { PaginationResponseDto } from "@data-access/response/pagination-response.dto";

export class RatingService extends BaseApi {
    private static instance: RatingService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};

        if (RatingService.store) {
            customHeader["x-tenant-slug"] = RatingService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super("market");
        RatingService.store = store;
    }

    public static getInstance(): RatingService {
        if (!RatingService.instance) {
            RatingService.instance = new RatingService();
        }
        return RatingService.instance;
    }

    public static resetInstance(store?: string): void {
        this.instance = new RatingService(store);
    }

    private readonly END_POINT = {
        getMyRatings: "v1/ratings/my-ratings",
        getProductRatings: "v1/ratings/product-ratings",
        createOrderRating: "v1/ratings",
        getOrderRatingByOrderId: "v1/ratings/orders",
        getUnratedOrders: "v1/ratings/unrated-orders",
        getProductRatingInfo: "v1/ratings/product-ratings/summary",
    };

    public async createRatingOrder(formData: FormData): Promise<boolean> {
        const { data } = await this.post<Response<boolean>>(
            this.END_POINT.createOrderRating,
            formData,
            {},
            this.customHeader(),
        );

        return !!data?.data;
    }

    public async getUnratedOrders(
        params: IUnratedOrderRequestDto,
    ): Promise<NOrderType.IOrderDetail[]> {
        const { data } = await this.get<Response<NOrderType.IOrderDetail[]>>(
            this.END_POINT.getMyRatings,
            params as any,
            this.customHeader(),
        );
        if (data?.data?.length) {
            return data?.data;
        }
        return [];
    }

    public async getMyRatings(
        params: IMyRatingRequestDto,
    ): Promise<IOrderRatingDto[]> {
        const { data } = await this.get<Response<IOrderRatingDto[]>>(
            this.END_POINT.getMyRatings,
            params as any,
            this.customHeader(),
        );
        if (data?.data?.length) {
            return data?.data;
        }
        return [];
    }

    public async getOrderRatingByOrderId(
        orderId: string,
    ): Promise<IOrderRatingDto | undefined> {
        const { data } = await this.get<Response<IOrderRatingDto>>(
            `${this.END_POINT.getOrderRatingByOrderId}/${orderId}`,
            {},
            this.customHeader(),
        );
        if (data?.data) {
            return data?.data;
        }
        return undefined;
    }

    public async getProductRatingInfo(
        productId: string,
    ): Promise<IProductRatingSummary | undefined> {
        const { data } = await this.get<Response<IProductRatingSummary>>(
            `${this.END_POINT.getProductRatingInfo}/${productId}`,
            {},
            this.customHeader(),
        );
        if (data?.data) {
            return data?.data;
        }
        return undefined;
    }

    public async getProductRatings(
        params: GetProductRatingsRequestDto,
    ): Promise<PaginationResponseDto<IItemRatingDto>> {
        const { data } = await this.get<
            Response<PaginationResponseDto<IItemRatingDto>>
        >(this.END_POINT.getProductRatings, params as any, this.customHeader());
        if (data?.data.data) {
            return data?.data;
        }
        return {
            data: [],
            total: 0,
        };
    }
}
