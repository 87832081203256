import { DrawerCustom } from "@components/modules/custom-drawer";
import { ItemVisibilityEnum } from "@enums/items-visibility.enum";
import { shopSelector } from "@redux/slices/shopSlice";
import { cn } from "@utils/tailwind-marge";
import useCheckMobileScreen from "@utils/use-check-mobile-screen";
import { Divider, Image, Skeleton, notification } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { isEqual } from "lodash";
import { useTranslation } from "next-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CleanUpCart } from "../../components/modules/clean-up-cart";
import { OrderItemTypeEnum } from "../../enums/order-item-type.enum";
import { ServiceEnum } from "../../enums/service.enum";
import { NCart } from "../../interfaces/components/cart";
import { NProduct } from "../../interfaces/components/product";
import {
    addToCart,
    cartSelector,
    incrementQuantity,
    removeItem,
    setOrderItemType,
} from "../../redux/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ProductService } from "../../services";
import { checkValidOrderTime } from "../../utils";
import { isNotEmpty } from "../../utils/array";
import { getPublicMediaUrl } from "../../utils/resource";
import { ISelectOptions } from "../product-option-type";
import s from "./product-in-cart.module.scss";
import { Block } from "./block";
import { Footer } from "./footer";
import { OrderNote } from "./note";

import { env } from "next-runtime-env";
import { ProductTypeEnum } from "../../enums/product-type.enum";
import { SimpleProduct } from "./product-type/simple";
import { ConfigurableProduct } from "./product-type/configurable";
import { ConfigProduct } from "./config-product";
import { ProductRating } from "./product-rating";
import { ProductConfigTypeEnum } from "../../enums/product-config-type.enum";

const productService = ProductService.getInstance();

export const ProductInCartTemplate: React.FC<{
    productId: string;
    onClose?: () => void;
    cartItemId?: string;
    categoryId: string;
    isEdit: boolean;
    resetProductId?: (productId: string) => void;
}> = ({
    productId,
    onClose,
    cartItemId,
    categoryId,
    isEdit,
    resetProductId,
}) => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const [product, setProduct] = useState<NProduct.IProduct | null>(null);
    const isMobile = useCheckMobileScreen();

    const [size, setSize] = useState<NProduct.IProductItemSize | null>(null);

    const [toppingGroups, setToppingGroups] = useState<
        NProduct.ILinkedProductGroups[]
    >([]);

    const [selectOptionTypes, setSelectOptionTypes] = useState<ISelectOptions>(
        {},
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disableCart, setDisableCart] = useState<boolean>(true);
    const [singleOptions, setSingleOptions] = useState<string[]>([]);
    const [note, setNote] = useState<string>();
    const [quantity, setQuantity] = useState<number>(1);
    const [cartItem, setCartItem] = useState<NCart.ICartItem>();

    const [toppingPrice, setToppingPrice] = useState<number>(0);
    const [toppingValid, setToppingValid] = useState<boolean>(true);

    const [isRemoveItem, setIsRemoveItem] = useState<boolean>(false);

    const cart = useAppSelector(cartSelector);
    const shop = useAppSelector(shopSelector);

    const [isOpenCleanUpCart, setIsOpenCleanUpCart] = useState<boolean>(false);

    const loadData = async () => {
        if (!productId) {
            return;
        }

        setIsLoading(true);
        const result = await productService.getProductDetail({
            id: productId,
            shopId: shop?.id || "",
        });

        if (!result) {
            setIsLoading(false);
            return;
        }

        // calculate price
        // always show origin price
        // result = calculateProductPrice(result);

        // prepare data
        if (result.photo) {
            result.photo = getPublicMediaUrl(result.photo);
        }

        const defaultSize = result?.productSizes?.find(
            (size) =>
                size.visibility !== ItemVisibilityEnum.UNAVAILABLE &&
                size.visibility !== ItemVisibilityEnum.HIDDEN,
        );
        result.productSizes?.length > 0 ? result.productSizes[0] : null;

        if (defaultSize) {
            if (defaultSize.discount) {
                result.price = defaultSize.discount.price;
                result.discount = defaultSize.discount;
            }

            result.price = defaultSize.price;
        }

        // set default topping by size
        if (defaultSize && !cartItemId) {
            setSize({
                id: defaultSize.id,
                name: defaultSize.size,
                discount: defaultSize?.discount,
                price: defaultSize?.price,
                sizeDescription: defaultSize?.sizeDescription,
            });
            setDisableCart(false);
        }

        // toppings
        if (isNotEmpty(result?.linkedProductGroups)) {
            if (!cartItemId) {
                result.linkedProductGroups?.forEach((lpg) => {
                    if (
                        lpg.required &&
                        lpg.linkedProducts.findIndex(
                            (product) =>
                                product?.visibility ===
                                ItemVisibilityEnum.VISIBLE,
                        ) >= 0
                    ) {
                        setToppingValid(false);
                    }
                });
            }

            //Update linkedProductGroups
            result.linkedProductGroups?.map((lpg) => {
                lpg.qty = 0;
                lpg.linkedProducts?.map((lp) => {
                    cart.items.map((item) => {
                        item.selectToppingGroups?.map((tp) => {
                            tp.linkedProducts.map((tplp) => {
                                if (
                                    lp.linkedProductId ===
                                        tplp.linkedProductId &&
                                    lp.linkedProductSizeId ===
                                        tplp.linkedProductSizeId &&
                                    lp.productId === tplp.productId &&
                                    lp.productSizeId === tplp.productSizeId
                                ) {
                                    lp.qty = tplp.qty || 0;
                                    lpg.qty += lp.qty;
                                }
                            });
                        });
                    });
                });
            });

            setToppingGroups(
                result.linkedProductGroups as NProduct.ILinkedProductGroups[],
            );

            //Update total topping price
            let toppingPrice = 0;
            result?.linkedProductGroups?.forEach((group) => {
                group.linkedProducts.forEach((product) => {
                    if (product.qty) {
                        toppingPrice += product?.discount
                            ? product.discount.price
                            : product.price;
                    }
                });
            });

            setToppingPrice(toppingPrice);
        }

        if (result?.applyToppingBySize) {
            if (!cartItemId) {
                setToppingGroups(defaultSize?.linkedProductGroups || []);
            }
        }

        if (!result?.productOptionTypes?.length) {
            setDisableCart(false);
        } else {
            const singleOptionIds: string[] = [];

            result?.productOptionTypes?.forEach((option) => {
                if (!option.isMultiChoice) {
                    singleOptionIds.push(option.id);
                }
            });

            setSingleOptions(singleOptionIds);
            if (!singleOptionIds?.length) {
                setDisableCart(false);
            }
        }

        setProduct(result);
        setIsLoading(false);
    };

    const cartItemTotal = useMemo(() => {
        let total = product?.discount
            ? product?.discount?.price || 0
            : product?.price || 0;

        if (size?.id) {
            const sizeFind = product?.productSizes?.find(
                (item) => item.id == size.id,
            );

            if (sizeFind) {
                total = sizeFind?.discount
                    ? sizeFind.discount.price
                    : sizeFind.price;
                setSize({
                    id: sizeFind.id,
                    name: sizeFind.size,
                    discount: sizeFind?.discount,
                    price: sizeFind?.price,
                    sizeDescription: sizeFind?.sizeDescription,
                });
            }
        }

        total += toppingPrice;

        return total;
    }, [product, size?.id, toppingPrice]);

    // const calculateProductPrice = (product: NProduct.IProduct) => {
    //     if (!shop.isPriceIncludedTax) {
    //         return product;
    //     }

    //     const tax = product.tax || 0;

    //     if (product.discount) {
    //         product.discount.price = calculatePriceIncludedTax(
    //             product.discount.price,
    //             tax,
    //         );
    //     }
    //     product.price = calculatePriceIncludedTax(product.price, tax);

    //     if (product.linkedProductGroups) {
    //         product.linkedProductGroups.forEach((lpg) => {
    //             lpg.linkedProducts?.forEach((lp) => {
    //                 if (lp.discount) {
    //                     lp.discount.price = calculatePriceIncludedTax(
    //                         lp.discount.price,
    //                         lp.tax || 0,
    //                     );
    //                 }
    //                 lp.price = calculatePriceIncludedTax(lp.price, lp.tax || 0);
    //             });
    //         });
    //     }

    //     if (product.productSizes.length) {
    //         product.productSizes.forEach((size) => {
    //             if (size.discount) {
    //                 size.discount.price = calculatePriceIncludedTax(
    //                     size.discount.price,
    //                     tax,
    //                 );
    //             }
    //             size.price = calculatePriceIncludedTax(size.price, tax);

    //             (size?.linkedProductGroups || []).forEach((lpg) => {
    //                 lpg.linkedProducts?.forEach((lp) => {
    //                     if (lp.discount) {
    //                         lp.discount.price = calculatePriceIncludedTax(
    //                             lp.discount.price,
    //                             lp.tax || 0,
    //                         );
    //                     }
    //                     lp.price = calculatePriceIncludedTax(
    //                         lp.price,
    //                         lp.tax || 0,
    //                     );
    //                 });
    //             });
    //         });
    //     }

    //     return product;
    // };

    useEffect(() => {
        if (productId) {
            loadData();
        }
    }, [productId]);

    useEffect(() => {
        const item = cart?.items?.find((data) => data.id === cartItemId);
        setCartItem(item);

        if (item?.productSizeId) {
            setSize({
                id: item.productSizeId,
                name: item.productName,
                discount: item?.discount,
                price: item?.price,
                sizeDescription: item?.productSizeName,
            });
        }

        if (item?.selectOptionTypes?.length) {
            item?.selectOptionTypes.forEach((ot) => {
                setSelectOptionTypes({
                    ...selectOptionTypes,
                    [ot?.id]: {
                        options: ot?.options,
                        isMultiChoice: ot?.isMultiChoice,
                        name: ot?.name,
                        optionTypeId: ot.optionTypeId,
                    },
                });
            });
        }

        setNote(item?.note);

        setQuantity(item?.qty || 1);
    }, [cartItemId]);

    const onSizeChange = (id: string) => {
        const productSize = product?.productSizes?.find(
            (size) => size.id === id,
        );

        setSize({
            id: id,
            name: productSize?.size,
            discount: productSize?.discount,
            price: productSize?.price || 0,
            sizeDescription: productSize?.sizeDescription,
        });

        if (product?.applyToppingBySize) {
            if (productSize?.linkedProductGroups?.length) {
                setToppingGroups(productSize.linkedProductGroups);
            } else {
                setToppingGroups(product?.linkedProductGroups || []);
            }
        }
    };

    const onAttributeChange = (
        attribute: NProduct.IProductAttribute | undefined,
    ) => {
        if (!attribute) {
            // TODO: disable confirm button or get default option
            setDisableCart(true);
            return;
        }
        setDisableCart(false);
        setSize({
            id: attribute?.id ?? "",
            name: attribute?.size,
            discount: attribute?.discount,
            price: attribute?.price || 0,
            sizeDescription: attribute?.sizeDescription,
        });
        if (product) {
            product.price = attribute.price;
            setProduct(product);
        }

        if (product?.applyToppingBySize) {
            if (attribute?.linkedProductGroups?.length) {
                setToppingGroups(attribute.linkedProductGroups);
            } else {
                setToppingGroups(product?.linkedProductGroups || []);
            }
        }
    };

    const onChangeOptions = (selectOptions: ISelectOptions) => {
        if (singleOptions?.length) {
            let length = 0;
            for (const property in selectOptions) {
                if (!selectOptions[property]?.isMultiChoice) {
                    length += 1;
                }
            }

            setDisableCart(!(length === singleOptions.length));
        }

        setSelectOptionTypes(selectOptions);
    };

    const removeCartItem = () => {
        dispatch(removeItem(cartItemId));
        setIsRemoveItem(true);
    };

    useEffect(() => {
        if (isRemoveItem) {
            onClose && onClose();
        }
    }, [isRemoveItem]);

    const handleAddToCart = () => {
        const cartItem: NCart.ICartItem = {
            id: uuidv4(),
            productId,
            productName: product?.name,
            price: cartItemTotal,
            selectToppingGroups: toppingGroups,
            total: cartItemTotal * quantity,
            qty: quantity,
            note: note?.trim(),
            selectOptionTypes: [],
            productSizeId: size?.id || undefined,
            productSizeName: size?.name || undefined,
            taxAmount: 0,
            taxPercent: product?.tax,
            productPhoto: product?.photo,
            discount: size?.id ? size?.discount : product?.discount,
            categoryId: categoryId,
            productType: product?.type,
        };

        if (!size?.id) {
            cartItem.taxAmount = product?.taxAmount
                ? product.taxAmount * quantity
                : 0;

            if (product?.discount) {
                cartItem.price =
                    cartItem.price - product?.discount?.price + product.price;
            }
        } else {
            const sizeTax = product?.productSizes.find(
                (item) => item.id === size.id,
            )?.taxAmount;
            cartItem.taxAmount = sizeTax ? sizeTax * quantity : 0;

            if (size?.discount) {
                cartItem.price =
                    cartItem.price - size?.discount?.price + size?.price;
            }
            const sizeDescription = size?.sizeDescription ?? "";
            const selectOptionTypes = product?.productOptionTypes?.map(
                (item) => {
                    console.log(item.productOptions);
                    const options = item.productOptions?.filter((op) =>
                        sizeDescription.includes(op.optionId),
                    );
                    item.productOptions = options;
                    return {
                        id: item.id,
                        name: item.name,
                        options: options,
                        isMultiChoice: item.isMultiChoice,
                        optionTypeId: item.optionTypeId,
                    };
                },
            );
            cartItem.selectOptionTypes = selectOptionTypes;
        }

        if (cartItem?.selectToppingGroups?.length) {
            cartItem.selectToppingGroups.forEach((lpg) => {
                lpg.linkedProducts.forEach((lp) => {
                    if (lp.qty) {
                        cartItem.taxAmount += lp.taxAmount
                            ? lp.taxAmount * quantity
                            : 0;
                    }
                });
            });
        }

        for (const property in selectOptionTypes) {
            if (property && cartItem.selectOptionTypes) {
                cartItem.selectOptionTypes.push({
                    id: property,
                    name: selectOptionTypes[property].name,
                    options: selectOptionTypes[property].options,
                    isMultiChoice: selectOptionTypes[property].isMultiChoice,
                    optionTypeId: selectOptionTypes[property].optionTypeId,
                });
            }
        }

        if (!cart.items?.length) {
            dispatch(addToCart(cartItem));
        } else {
            if (cartItemId) {
                dispatch(removeItem(cartItemId));
                dispatch(addToCart(cartItem));
            } else {
                let index = 0;
                for (const item of cart.items) {
                    cartItem.id = item.id;

                    if (!_checkEqualItem(cartItem, item)) {
                        dispatch(
                            incrementQuantity({
                                id: item.id,
                                qty: cartItem.qty,
                            }),
                        );
                        break;
                    } else {
                        index += 1;
                    }
                }

                if (index === cart.items.length) {
                    cartItem.id = uuidv4();
                    dispatch(addToCart(cartItem));
                }
            }
        }

        if (!isMobile) {
            notification.success({
                message: t("add_to_cart_success", { item: product?.name }),
                placement: "topRight",
                // top: 85,
                className: "custom-notification",
                icon: <img src="/icons/done-circle-icon.svg" alt="" />,
            });
        }
        onClose && onClose();
    };

    const checkOrderItemTypeChange = () => {
        const isValid = checkValidOrderTime(
            shop.fromHour,
            shop.toHour,
            shop.orderStoppageTime,
        );

        if (!isValid) {
            notification.error({
                message: "",
                description: t("order.error_order_time_out"),
            });
            return;
        }
        if (
            cart &&
            cart.items?.length &&
            cart.orderItemType !== OrderItemTypeEnum.PRODUCT
        ) {
            setIsOpenCleanUpCart(true);
        } else {
            if (!cart.items?.length) {
                dispatch(setOrderItemType(OrderItemTypeEnum.PRODUCT));
            }

            handleAddToCart();
        }
    };

    const changeNote = (event: any) => {
        setNote(event?.target?.value);
    };

    const _checkEqualItem = (
        currentItem: NCart.ICartItem,
        newItem: NCart.ICartItem,
    ): boolean => {
        const properties = [
            "categoryId",
            "productId",
            "productSizeId",
            "note",
            "selectOptionTypes",
            "selectToppingGroups",
        ];

        return properties.some((p) => {
            return ["selectOptionTypes", "selectToppingGroups"].includes(p)
                ? !isEqual(
                      currentItem[p as keyof NCart.ICartItem],
                      newItem[p as keyof NCart.ICartItem],
                  )
                : currentItem[p as keyof NCart.ICartItem] !==
                      newItem[p as keyof NCart.ICartItem];
        });
    };

    const onToppingChange = (
        value: CheckboxValueType[],
        index: number,
        valid: boolean,
    ) => {
        const linkedProducts: NProduct.ILinkedProduct[] = [];
        let toppingPrice = 0;
        const toppingGroupsCopy: NProduct.ILinkedProductGroups[] = [];

        toppingGroups.forEach((group) => {
            if (group.id !== toppingGroups[index].id) {
                toppingGroupsCopy.push(group);
            } else {
                const groupCP = { ...group };
                groupCP.linkedProducts = [];
                groupCP.qty = 0;
                group.linkedProducts.forEach((product) => {
                    const productCP = { ...product };
                    if (value?.includes(product.id)) {
                        productCP.qty = 1;
                        linkedProducts.push(productCP);
                    } else {
                        productCP.qty = 0;
                    }

                    groupCP.qty += productCP.qty;
                    groupCP.linkedProducts.push(productCP);
                });

                toppingGroupsCopy.push(groupCP);
            }
        });

        toppingGroupsCopy.forEach((group) => {
            group.linkedProducts.forEach((product) => {
                if (product.qty) {
                    if (product?.discount) {
                        toppingPrice += product?.discount?.price;
                    } else {
                        toppingPrice += product.price;
                    }
                }
            });
        });

        setToppingPrice(toppingPrice);
        setToppingValid(valid);
        setToppingGroups([...toppingGroupsCopy]);
    };

    const isFFood = env("NEXT_PUBLIC_MAIN_LOGO") === ServiceEnum.FFOOD;

    const ShowProduct = () => {
        if (!product) {
            return <></>;
        }
        const type = product?.type;
        let productComponent = <></>;
        if (type === ProductTypeEnum.SIMPLE) {
            productComponent = (
                <SimpleProduct
                    isEdit={false}
                    product={product}
                    toppingGroups={[]}
                    cartItem={cartItem}
                    onToppingChange={onToppingChange}
                    onSizeChange={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                    onChangeOptions={function (): void {
                        throw new Error("Function not implemented.");
                    }}
                />
            );
        } else if (type === ProductTypeEnum.CONFIGURABLE) {
            productComponent = (
                <ConfigurableProduct
                    isEdit={false}
                    product={product}
                    defaultSizeId={size?.id}
                    onChange={onAttributeChange}
                />
            );
        }
        return productComponent;
    };

    return (
        <>
            <DrawerCustom
                closable={true}
                onClose={onClose}
                open={true}
                placement={isMobile ? "bottom" : "right"}
                className={cn(
                    s.orderDrawer,
                    isEdit && "rounded-tl-3xl rounded-tr-3xl",
                )}
                height={isEdit ? "90%" : "100%"}
                closeIcon={
                    isEdit ? (
                        <div
                            className={cn(
                                "w-16 h-2 bg-neutral-10 rounded-md absolute",
                                "left-1/2 translate-x-[-50%]",
                                "hover:bg-neutral-20 transition",
                            )}
                        ></div>
                    ) : (
                        <Image
                            alt="close"
                            src="/icons/remove.svg"
                            preview={false}
                        />
                    )
                }
                key="addToCart"
                width={isMobile ? undefined : 600}
                footer={
                    <Footer
                        cartItem={cartItem}
                        cartItemTotal={cartItemTotal}
                        checkOrderItemTypeChange={checkOrderItemTypeChange}
                        onClose={onClose}
                        quantity={quantity}
                        removeCartItem={removeCartItem}
                        setQuantity={setQuantity}
                        cartItemId={cartItemId}
                        disableCart={disableCart}
                        isFFood={isFFood}
                        toppingValid={toppingValid}
                    />
                }
            >
                {isLoading || !product ? (
                    <Skeleton active />
                ) : (
                    <>
                        {(!product?.type ||
                            product?.type === ProductTypeEnum.SIMPLE) && (
                            <SimpleProduct
                                isEdit={false}
                                product={product}
                                toppingGroups={[]}
                                cartItem={cartItem}
                                onToppingChange={onToppingChange}
                                onSizeChange={onSizeChange}
                                onChangeOptions={onChangeOptions}
                            />
                        )}
                        {product?.type === ProductTypeEnum.CONFIGURABLE && (
                            <ConfigurableProduct
                                isEdit={false}
                                product={product}
                                defaultSizeId={size?.id}
                                onChange={onAttributeChange}
                            />
                        )}
                        <Block className="p-0 divider">
                            <OrderNote setNote={setNote} note={note} />
                        </Block>

                        {product?.relatedProductIds?.length && (
                            <Block className="p-0 divider">
                                <ConfigProduct
                                    title={t("marketplace.related_products")}
                                    product={product}
                                    productConfigType={
                                        ProductConfigTypeEnum.RELATED
                                    }
                                    resetProductId={resetProductId}
                                ></ConfigProduct>
                            </Block>
                        )}

                        {product?.crossSellProductIds?.length && (
                            <Block className="p-0 divider">
                                <ConfigProduct
                                    title={t("marketplace.cross_sell_products")}
                                    product={product}
                                    productConfigType={
                                        ProductConfigTypeEnum.CROSSSELL
                                    }
                                    resetProductId={resetProductId}
                                ></ConfigProduct>
                            </Block>
                        )}

                        {product?.upsellProductIds?.length && (
                            <Block className="p-0 divider">
                                <ConfigProduct
                                    title={t("marketplace.upsell_products")}
                                    product={product}
                                    productConfigType={
                                        ProductConfigTypeEnum.UPSELL
                                    }
                                    resetProductId={resetProductId}
                                ></ConfigProduct>
                            </Block>
                        )}

                        <Block className="p-0 divider">
                            <ProductRating productId={product.id} />
                        </Block>
                    </>
                )}
            </DrawerCustom>
            <CleanUpCart
                isOpen={isOpenCleanUpCart}
                orderItemType={OrderItemTypeEnum.PRODUCT}
                handleAddToCart={handleAddToCart}
                handleClose={() => {
                    setIsOpenCleanUpCart(false);
                }}
                isMobile={isMobile}
            />
        </>
    );
};
