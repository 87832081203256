import { useTranslation } from "next-i18next";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { QueryGraphQL } from "@interfaces/components/query-graphql";
import { IGiftCardCode } from "@interfaces/components/gift-card-code";

import BaseGraphQL from "./baseGraphQL";

export class GiftCardCodeGraphQL extends BaseGraphQL {
    public getList(
        variables: QueryGraphQL.IGetLists,
    ): QueryGraphQL.IResponse<IGiftCardCode.Request[] | null> {
        const queryName = "getCodes";
        const query = gql`
            query (
                $sort: String
                $start: Int
                $limit: Int
                $moduleType: String
                $textSearch: String
                $isDisable: Boolean
                $isNotExpired: Boolean
                $isNotOutOfBalance: Boolean
            ) {
                ${queryName}(
                    sort: $sort
                    start: $start
                    limit: $limit
                    moduleType: $moduleType
                    textSearch: $textSearch    
                    isDisable: $isDisable
                    isNotExpired: $isNotExpired
                    isNotOutOfBalance: $isNotOutOfBalance
                ) {
                    data {
                        id
                        code
                        remainingBalance
                        isDisable
                        expiredTime
                        giftCard {
                            id
                            name
                            image
                            balance
                            price
                            expiredDate
                            periodTimeType
                            description
                        }
                    }
                    total
                }
            }
        `;

        const [refetch, { data, loading, error }] = useLazyQuery(query, {
            variables: {
                ...variables,
                moduleType: this.getModuleType(),
            },
            fetchPolicy: "no-cache",
        });

        return {
            data: data ? data[queryName]?.data : null,
            loading,
            error,
            refetch,
        };
    }

    public getById(
        id: string,
    ): QueryGraphQL.IResponse<IGiftCardCode.Request | null> {
        const queryName = "getCodeById";
        const query = gql`
            query ($id: ID!) {
                ${queryName}(id: $id) {
                    id
                    balance
                    remainingBalance
                    expiredTime
                    giftCard {
                        name
                        image
                        expiredDate
                        periodTimeType
                        description
                    }
                    giftCardTransactions {
                        orderIncrementId
                        createdAt
                        balance
                        method
                    }
                }
            }
        `;

        const [getById, { data, loading, error }] = useLazyQuery(query, {
            variables: {
                id,
            },
            fetchPolicy: "no-cache",
        });

        return {
            data: data ? data[queryName] : null,
            loading,
            error,
            refetch: getById,
        };
    }

    public getByIds(): QueryGraphQL.IResponse<IGiftCardCode.Request[] | null> {
        const queryName = "getCodeByIds";
        const query = gql`
            query ($ids: [ID!]!) {
                ${queryName}(ids: $ids) {
                    id
                    remainingBalance
                    giftCard {
                        id
                        name
                        image
                    }
                }
            }
        `;

        const [getByIds, { data, loading, error }] = useLazyQuery(query, {
            variables: {},
            fetchPolicy: "no-cache",
        });

        return {
            data: data ? data[queryName] : null,
            loading,
            error,
            refetch: getByIds,
        };
    }

    public getByCode(): QueryGraphQL.IResponse<IGiftCardCode.Request | null> {
        const { t } = useTranslation("common");

        const queryName = "getGcCodeByCode";
        const query = gql`
            query ($code: String!, $moduleType: String!) {
                ${queryName}(code: $code, moduleType: $moduleType) {
                    id
                    remainingBalance
                    expiredTime
                    giftCard {
                        id
                        name
                    }
                }
            },
        `;

        const [getCode, { data, loading, error }] = useLazyQuery(query, {
            variables: {
                moduleType: this.getModuleType(),
            },
            fetchPolicy: "no-cache",
            onError: (err) => {
                this.createErrorNotification(
                    t(`gift_card.error.${err.message}`),
                );
            },
        });

        return {
            data: data ? data[queryName] : null,
            loading,
            error,
            refetch: getCode,
        };
    }

    public redeemGiftCard(
        giftCardCode: string,
    ): QueryGraphQL.IResponse<IGiftCardCode.Request | null> {
        const { t } = useTranslation("common");

        const mutationName = "redeemGiftCard";
        const mutation = gql`
            mutation ($redeemRequest: RedeemGiftCard!) {
                ${mutationName}(redeemRequest: $redeemRequest)
            }
        `;

        const [redeemGiftCard, { data, loading, error }] = useMutation(
            mutation,
            {
                variables: {
                    redeemRequest: {
                        giftCardCode,
                        moduleType: this.getModuleType(),
                    },
                },
                fetchPolicy: "no-cache",
                onError: (err) => {
                    this.createErrorNotification(
                        t(`gift_card.error.${err.message}`),
                    );
                },
                onCompleted: () => {
                    this.createSuccessNotification(
                        t("gift_card.success.redeem"),
                    );
                },
            },
        );

        return {
            data: data ? data[mutationName] : null,
            loading,
            error,
            function: redeemGiftCard,
        };
    }

    public checkGcCodeValid(): QueryGraphQL.IResponse<IGiftCardCode.Request | null> {
        const { t } = useTranslation("common");

        const queryName = "checkGcCode";
        const query = gql`
            query ($id: ID!, $moduleType: String!) {
                ${queryName}(id: $id, moduleType: $moduleType) 
            }
        `;

        const [checkGcCode, { data, loading, error }] = useLazyQuery(query, {
            variables: {
                moduleType: this.getModuleType(),
            },
            fetchPolicy: "no-cache",
            onError: (err) => {
                this.createErrorNotification(
                    t(`gift_card.error.${err.message}`),
                );
            },
        });

        return {
            data: data ? data[queryName] : null,
            loading,
            error,
            refetch: checkGcCode,
        };
    }
}
