export enum OrderStatusEnum {
    DRAFT = "draft",
    WAITING = "waiting",
    CONFIRM = "confirm",
    REJECT = "reject",
    PROCESSING = "processing",
    CANCELED = "canceled",
    COMPLETED = "completed",
    REFUNDED = "refunded",
}
