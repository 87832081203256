import { NLocation } from "@interfaces/components/location";
import BaseApi from "./baseApi.service";
import Response from "../../data-access/response/response";
import { BannerSettingDto } from "@data-access/models/banner-setting.model";
import { NMarket } from "../../interfaces/components/market";

export class MarketService extends BaseApi {
    private static instance: MarketService;
    constructor() {
        super("market");
    }
    public static getInstance(): MarketService {
        if (!MarketService.instance) {
            MarketService.instance = new MarketService();
        }
        return MarketService.instance;
    }

    public static resetInstance(): void {
        this.instance = new MarketService();
    }

    private readonly END_POINT = {
        bannerSetting: "v1/setting/banners",
        location: "v1/location",
        myOrders: "v1/user/my-orders",
    };

    public async getStates(): Promise<NLocation.IState[]> {
        const { data } = await this.get<Response<NLocation.IState[]>>(
            `${this.END_POINT.location}/state`,
        );
        return data?.data || [];
    }

    public async getCities(stateId: number): Promise<NLocation.ICity[]> {
        const { data } = await this.get<Response<NLocation.ICity[]>>(
            `${this.END_POINT.location}/cities/${stateId}`,
        );
        return data?.data || [];
    }

    public async getBannerSetting(): Promise<BannerSettingDto | undefined> {
        const { data } = await this.get<BannerSettingDto>(
            this.END_POINT.bannerSetting,
            {},
        );
        if (data) {
            return data ?? undefined;
        }
        return undefined;
    }

    public async getOrders(
        query: NMarket.IGetMyOrderRequest,
    ): Promise<NMarket.IMyOrder[]> {
        const { data } = await this.get<NMarket.IMyOrder[]>(
            `${this.END_POINT.myOrders}`,
            query as any,
        );
        return data || [];
    }
}
