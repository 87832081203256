import React, { useEffect, useState } from "react";

import { NProduct } from "../../../../interfaces/components/product";
import { getAttributeGroup, UiProductAttributeType } from "./getAttributeGroup";
import { cn } from "../../../../utils/tailwind-marge";
import { ItemVisibilityEnum } from "../../../../enums/items-visibility.enum";

export const ProductAttributes: React.FC<{
    attributes: NProduct.IProductAttribute[];
    onChange?: (attribute: NProduct.IProductAttribute | undefined) => void;
    defaultAttributeId?: string;
}> = ({ attributes, onChange, defaultAttributeId }) => {
    const [attributeGroups, setAttributeGroups] = useState<
        Record<string, UiProductAttributeType[]>
    >(getAttributeGroup(defaultAttributeId, attributes));

    useEffect(() => {
        if (defaultAttributeId) {
            setAttributeGroups(
                getAttributeGroup(defaultAttributeId, attributes),
            );
        }
    }, [defaultAttributeId]);
    const onSelectAttribute = (
        group: string,
        attribute: UiProductAttributeType,
    ) => {
        const cloned = { ...attributeGroups };
        const attrs = [...cloned[group]];
        attrs.forEach((attr) => {
            if (attr.name === attribute.name) {
                attr.isSelected = !attr.isSelected;
            } else {
                attr.isSelected = false;
            }
        });
        attributeGroups[group] = attrs;
        checkAvailability(cloned, attribute, group);
        setAttributeGroups(cloned);
        console.log(cloned);

        const attr = getProductAttributeId();
        onChange?.(attr);
    };

    const getProductAttributeId = () => {
        let attrKey = "";
        Object.entries(attributeGroups).map((entry) => {
            const key = entry[0];
            attrKey = `${attrKey}[${key}-${
                entry[1]?.find((attrArray) => attrArray.isSelected)?.name ?? ""
            }]`;
        });
        return attributes?.find((item) => item.size === attrKey);
    };

    const checkAvailability = (
        clonedAttributeGroups: Record<string, UiProductAttributeType[]>,
        attr: UiProductAttributeType,
        group: string,
    ) => {
        const notAvailableItem = attributes.find(
            (item) =>
                item.visibility !== ItemVisibilityEnum.VISIBLE &&
                item.size?.includes(attr.name),
        );
        const keys = Object.keys(clonedAttributeGroups).filter(
            (k) => k !== group,
        );
        for (const key of keys) {
            const attrs = clonedAttributeGroups[key] ?? [];
            attrs.forEach((attr) => {
                attr.visibility = ItemVisibilityEnum.VISIBLE;
                attr.visibility = !notAvailableItem?.size?.includes(attr.name)
                    ? ItemVisibilityEnum.VISIBLE
                    : ItemVisibilityEnum.UNAVAILABLE;
            });
        }

        return;
    };

    return (
        <>
            <div className="flex flex-col gap-4">
                {Object.keys(attributeGroups)?.map((key: string) => {
                    const attrs = attributeGroups[key] ?? [];
                    return (
                        <>
                            <div>
                                <span className="font-bold text-[16px]">
                                    {key}
                                </span>
                                <span className="text-red-500 ">*</span>
                            </div>
                            <div className="flex gap-2">
                                {attrs.map((attr) => {
                                    return (
                                        <>
                                            {attr.visibility ===
                                            ItemVisibilityEnum.VISIBLE ? (
                                                <span
                                                    onClick={() =>
                                                        onSelectAttribute(
                                                            key,
                                                            attr,
                                                        )
                                                    }
                                                    className={cn(
                                                        "min-w-[80px] px-2 max-w-lg py-[6px] text-center rounded-[4px] cursor-pointer items-center text-[14px]",
                                                        attr.isSelected
                                                            ? " bg-slate-800 text-white"
                                                            : " bg-gray-100 text-black",
                                                    )}
                                                >
                                                    {attr.name}
                                                </span>
                                            ) : (
                                                <span
                                                    className={cn(
                                                        "min-w-[80px] px-2 py-[6px] text-center rounded-[4px] bg-gray-400 text-white text-[14px]",
                                                    )}
                                                >
                                                    {attr.name}
                                                </span>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    );
};
