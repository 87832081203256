import { IMarketLocation } from "./market-location";

export enum EAddressType {
    BillingAddress = "billing_address",
    ShippingAddress = "shipping_address",
}

export enum EShippingAddressType {
    COMPANY = "company",
    HOME = "home",
    OTHER = "other",
}

export interface IOrderAddressDto {
    id?: string;

    name?: string;

    fullName?: string;

    phoneNumber?: string;

    note?: string;
    street: string;

    type: EAddressType;

    shippingAddressType?: EShippingAddressType;

    cityName: string;
    cityCode: string;
    cityId: string;

    districtName: string;
    districtCode: string;
    districtId: string;

    wardName: string;
    wardCode: string;
    wardId: string;

    active?: boolean;

    lat?: number;
    lng?: number;
}

export interface ILatLng {
    lat?: number;
    lng?: number;
}
export type Address = {
    fullName: string;
    phoneNumber: string;
    shippingAddressType: EShippingAddressType;
    city: IMarketLocation;
    district: IMarketLocation;
    ward: IMarketLocation;
    street: string;
    note?: string;
};
