import { notification } from "antd";
import { env } from "next-runtime-env";

export default class BaseGraphQL {
    public createSuccessNotification(
        description: string | React.ReactNode,
        message?: string,
    ): void {
        notification.success({
            className: "success-notification",
            message: message || "",
            description: description,
            duration: 4,
        });
    }

    public createErrorNotification(
        description: string | React.ReactNode,
        message?: string,
    ): void {
        notification.error({
            className: "error-notification",
            message: message || "",
            description: description,
            duration: 4,
        });
    }

    public getModuleType(): string {
        return env("NEXT_PUBLIC_MODULE_TYPE") ?? "";
    }
}
