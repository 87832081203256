"use client";

import { CashIcon } from "@components/icons/CashIcon";
import { CreditIcon } from "@components/icons/CreditIcon";
import { MasterCardIcon } from "@components/icons/MasterCardIcon";
import { MomoIcon } from "@components/icons/MomoIcon";
import { QRIcon } from "@components/icons/QRIcon";
import { ZaloPayIcon } from "@components/icons/ZaloPayIcon";
import { tableSelector } from "@redux/slices/tableSlice";
import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    CONFIG_PAYMENT_SETTING,
    GPAY_SERVICE_PROVIDER,
    ServiceProviderEnum,
} from "../../../constants";
import { CreditProviderEnum, PaymentMethodEnum } from "../../../enums";
import { NPaymentSetting } from "../../../interfaces/components/payment-setting";
import {
    addPaymentMethod,
    paymentSelector,
} from "../../../redux/slices/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import s from "./payment-method-list.module.scss";

interface IActiveMethodService extends NPaymentSetting.IServiceProvider {
    label: string;
    image?: string;
    isSelected?: boolean;
}

interface IActiveMethod {
    type: PaymentMethodEnum | CreditProviderEnum;
    services: IActiveMethodService[];
    isSelected?: boolean;
}

enum MethodEnum {
    CASH = "cash",
    CREDIT = "credit",
}

export const PaymentMethodList: React.FC<
    NPaymentSetting.IPaymentSettingProps
> = ({ paymentSettings, orderType, onSelect }) => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const payment = useAppSelector(paymentSelector);

    const servicesProvider = GPAY_SERVICE_PROVIDER;

    const [paymentMethods, setPaymentMethods] = useState<
        Record<
            MethodEnum,
            {
                paymentProvider?: Record<
                    MethodEnum,
                    {
                        label: string;
                        value: string;
                        isSelected?: boolean;
                        provider?: IActiveMethod;
                    }
                >;
                display?: { label: string; value: string };
            }
        >
    >();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
        Record<
            MethodEnum,
            Record<
                MethodEnum,
                {
                    label: string;
                    value: string;
                    provider?: IActiveMethod;
                    isSelected?: boolean;
                }
            >
        >
    >();

    useEffect(() => {
        if (!paymentSettings?.methods?.length) {
            return;
        }

        //ignore traditional
        const _paymentMethods: Record<
            string,
            | {
                  paymentProvider?: Record<
                      CreditProviderEnum,
                      {
                          label: string;
                          value: string;
                          provider?: IActiveMethod;
                      }
                  >;
                  display?: { label: string; value: string };
              }
            | undefined
        > = {
            [MethodEnum.CREDIT]: undefined,
        };

        const _creditProviders: Record<
            string,
            { label: string; value: string; provider?: IActiveMethod }
        > = {};

        paymentSettings.methods.forEach((method) => {
            if (!method.isActive) {
                return;
            }

            const type = method.type as PaymentMethodEnum;

            const _method: IActiveMethod = {
                type,
                services: [],
            };

            switch (method.type) {
                case CreditProviderEnum.GPAY:
                    {
                        for (const service of servicesProvider) {
                            if (
                                !method[
                                    service as keyof NPaymentSetting.IGPayPaymentMethodSetting
                                ]
                            ) {
                                continue;
                            }

                            const provider = CONFIG_PAYMENT_SETTING[service];
                            const label = t(
                                `payment_setting.${provider.label}`,
                            );

                            _method.services.push({
                                ...provider,
                                ...{ label: label },
                            });
                        }

                        _creditProviders[PaymentMethodEnum.GPAY] = {
                            label: method.name
                                ? method.name
                                : t(`payment_setting.${method.type}`),
                            value: method.type,
                            provider: { ..._method },
                        };
                    }
                    break;

                case CreditProviderEnum.ONEPAY:
                    {
                        _creditProviders[CreditProviderEnum.ONEPAY] = {
                            label: method.name
                                ? method.name
                                : t(`payment_setting.${method.type}`),
                            value: method.type,
                        };
                    }
                    break;

                case CreditProviderEnum.SMARTPAY:
                    {
                        _creditProviders[CreditProviderEnum.SMARTPAY] = {
                            label: method.name
                                ? method.name
                                : t(`payment_setting.${method.type}`),
                            value: method.type,
                        };
                    }
                    break;

                case PaymentMethodEnum.CASH:
                    {
                        _paymentMethods[PaymentMethodEnum.CASH] = {
                            display: {
                                label: t(`payment_setting.cash`),
                                value: PaymentMethodEnum.CASH,
                            },
                        };
                    }
                    break;
                case CreditProviderEnum.BAOKIM:
                    {
                        _creditProviders[CreditProviderEnum.BAOKIM] = {
                            label: method.name
                                ? method.name
                                : t(`payment_setting.baokim`),
                            value: method.type,
                        };
                    }
                    break;
            }
        });

        if (Object.keys(_creditProviders)?.length) {
            _paymentMethods[PaymentMethodEnum.CREDIT] = {
                paymentProvider: _creditProviders,
            };
        }

        // not show deduct
        // if (isFFood && orderType === OrderTypeEnum.TOGO) {
        //     _paymentMethods[PaymentMethodEnum.DEDUCT] = {
        //         display: {
        //             label: t(`payment_setting.deduct`),
        //             value: PaymentMethodEnum.DEDUCT,
        //         },
        //     };
        // }

        for (const property in _paymentMethods) {
            if (!_paymentMethods[property]) {
                delete _paymentMethods[property];
            }
        }

        const keys = Object.keys(_paymentMethods || {});

        if (!keys?.length || !_paymentMethods) {
            return;
        }

        setPaymentMethods({ ...(_paymentMethods as any) });
    }, [paymentSettings, orderType]);

    useEffect(() => {
        if (!selectedPaymentMethod && paymentMethods) {
            const keys = payment?.paymentMethod
                ? [payment?.paymentMethod]
                : Object.keys(paymentMethods || {});

            onSelectPaymentMethod(keys[0], {
                paymentProvider: payment?.paymentProvider,
                serviceProvider: payment?.serviceProvider,
                provider: payment?.provider,
            });
        }
    }, [paymentMethods]);

    useEffect(() => {
        const _selectedPaymentMethod = { ...selectedPaymentMethod };

        if (!_selectedPaymentMethod) {
            onSelect && onSelect(null);
            return;
        }

        const keys = Object?.keys(_selectedPaymentMethod);

        const request: NPaymentSetting.IPaymentMethodResponse = {
            paymentMethod: keys[0] as PaymentMethodEnum,
            paymentProvider: "",
            serviceProvider: "",
        };

        if (_selectedPaymentMethod[keys[0] as MethodEnum]) {
            loop1: for (const [property, value] of Object.entries(
                _selectedPaymentMethod[keys[0] as MethodEnum] || {},
            )) {
                if (value.isSelected) {
                    request.paymentProvider = property;

                    if (value.provider?.services?.length) {
                        value.provider?.services.forEach((service) => {
                            if (service.isSelected) {
                                request.serviceProvider = service.method;
                                request.provider = service.serviceProvider;
                            }
                        });
                    }

                    break loop1;
                }
            }
        }

        request?.paymentMethod && dispatch(addPaymentMethod(request));

        onSelect && onSelect(request);
    }, [selectedPaymentMethod]);

    const DisplayServiceProvider = ({
        service,
        paymentMethod,
        paymentProvider,
    }: {
        service: IActiveMethodService;
        paymentMethod: string;
        paymentProvider: string;
    }) => {
        const serviceProvider = service.serviceProvider as ServiceProviderEnum;

        let Icon = <CreditIcon />;

        switch (serviceProvider) {
            case ServiceProviderEnum.MOMO:
                Icon = <MomoIcon />;
                break;
            case ServiceProviderEnum.QRPAY:
                Icon = <QRIcon />;
                break;
            case ServiceProviderEnum.VISA:
                Icon = <MasterCardIcon />;
                break;
            case ServiceProviderEnum.ZALO:
                Icon = <ZaloPayIcon />;
                break;

            default:
                Icon = <CreditIcon />;
                break;
        }

        const services = selectedPaymentMethod
            ? (
                  (selectedPaymentMethod[paymentMethod as MethodEnum] || {})[
                      paymentProvider as MethodEnum
                  ] || {}
              )?.provider?.services || []
            : [];

        const onSelectServiceProvider = (service: IActiveMethodService) => {
            const _selectedPaymentMethod = { ...selectedPaymentMethod };
            if (!_selectedPaymentMethod) {
                return;
            }

            (services || []).forEach((s) => {
                s.isSelected =
                    s.serviceProvider === service.serviceProvider &&
                    s.method === service.method;
            });

            setSelectedPaymentMethod({ ...(_selectedPaymentMethod as any) });
        };

        const selectedService = (services || []).find(
            (_service) => _service.isSelected,
        );

        return (
            <div
                className={`payment-service-provider ${s.serviceProvider} ${
                    selectedService?.serviceProvider ===
                        service.serviceProvider &&
                    selectedService?.method === service.method
                        ? s.selected
                        : ""
                } flex items-center`}
                key={uuidv4()}
                onClick={() => onSelectServiceProvider(service)}
            >
                <div
                    className={`${s.paymentMethodIcon} flex items-center justify-center mr-2`}
                >
                    {Icon}
                </div>

                <div className={`${s.paymentMethodContent}`}>
                    <h4 className={`${s.serviceProviderTitle}`}>
                        {service.label}
                    </h4>
                </div>
            </div>
        );
    };

    const paymentMethodKeys = paymentMethods ? Object.keys(paymentMethods) : [];
    const paymentProviderKeys = selectedPaymentMethod
        ? Object.keys(selectedPaymentMethod)
        : [];

    const onSelectPaymentMethod = (
        paymentMethod: string,
        optional?: {
            paymentProvider?: string;
            serviceProvider?: string;
            provider?: string;
        },
    ) => {
        const _paymentMethods = paymentMethods
            ? paymentMethods[paymentMethod as MethodEnum]
            : undefined;

        if (!_paymentMethods) {
            return;
        }

        const providers = { ..._paymentMethods.paymentProvider };

        for (const [key, value] of Object?.entries(providers)) {
            value.isSelected = false;

            if (optional?.paymentProvider) {
                if (key === optional?.paymentProvider) {
                    value.isSelected = true;
                }
            }

            if (value.provider?.services?.length) {
                value.provider.services.forEach((service, index) => {
                    if (!optional?.serviceProvider) {
                        service.isSelected = index === 0;
                    } else {
                        if (
                            service.method === optional.serviceProvider &&
                            service.serviceProvider === optional.provider
                        ) {
                            service.isSelected = true;
                        }
                    }
                });
            }
        }

        if (!optional?.paymentProvider) {
            (Object.values(providers)[0] || { isSelected: false }).isSelected =
                true;
        }

        const _data: any = {
            [paymentMethod as MethodEnum]: _paymentMethods.paymentProvider,
        };

        setSelectedPaymentMethod({ ..._data });
    };

    const onSelectPaymentProvider = (
        paymentKey: string,
        paymentProviderKey: string,
    ) => {
        const _selectedPaymentMethod = { ...selectedPaymentMethod };

        Object.keys(
            _selectedPaymentMethod[paymentKey as MethodEnum] || {},
        ).forEach((key) => {
            if (key === paymentProviderKey) {
                (
                    (_selectedPaymentMethod[paymentKey as MethodEnum] || {})[
                        paymentProviderKey as MethodEnum
                    ] || { isSelected: false }
                ).isSelected = true;

                const services = (_selectedPaymentMethod[
                    paymentKey as MethodEnum
                ] || {})[paymentProviderKey as MethodEnum]?.provider?.services;

                if (services?.length) {
                    services[0].isSelected = true;
                }
            } else {
                (
                    (_selectedPaymentMethod[paymentKey as MethodEnum] || {})[
                        key as MethodEnum
                    ] || { isSelected: false }
                ).isSelected = false;
            }
        });

        setSelectedPaymentMethod({ ...(_selectedPaymentMethod as any) });
    };

    const DisplayPaymentMethod = ({
        paymentMethod,
    }: {
        paymentMethod: string;
    }) => {
        let _data = { label: "", note: "", icon: <CashIcon /> };

        switch (paymentMethod) {
            case MethodEnum.CASH:
                _data = {
                    label: t("order.cash"),
                    note: t("order.cash_note"),
                    icon: <CashIcon />,
                };
                break;
            case MethodEnum.CREDIT:
                _data = {
                    label: t("order.credit"),
                    note: t("order.cash_note"),
                    icon: <CreditIcon />,
                };
                break;
        }

        return (
            <div
                className={`${s.paymentMethod}  ${
                    selectedPaymentMethod &&
                    Object.keys(selectedPaymentMethod)[0] === paymentMethod
                        ? s.selected
                        : ""
                } flex items-start`}
                key={uuidv4()}
                onClick={() => onSelectPaymentMethod(paymentMethod)}
            >
                <div
                    className={`${s.paymentMethodIcon} flex items-center justify-center mr-2`}
                >
                    {_data.icon}
                </div>

                <div
                    className={`${s.paymentMethodContent} flex flex-col justify-start items-start gap-y-2`}
                >
                    <h4 className={`${s.paymentMethodName} caption-bold`}>
                        {_data.label}
                    </h4>
                    <span>{_data.note}</span>
                </div>
            </div>
        );
    };

    const DisplayPaymentMethodProvider = ({
        paymentKey,
    }: {
        paymentKey: string;
    }) => {
        const _data = selectedPaymentMethod
            ? selectedPaymentMethod[paymentKey as MethodEnum]
            : undefined;

        if (!_data) {
            return <></>;
        }

        const paymentProviderKeys = Object.keys(_data);
        return (
            <section>
                <div className="flex mb-2">
                    <span className={`${s.methodTitle} caption-medium`}>
                        {t(`order.${paymentKey}`)}
                    </span>
                </div>

                <div className="flex items-start flex-wrap">
                    {paymentProviderKeys.map((value) => (
                        <div
                            className={`${s.paymentMethod} ${
                                _data && _data[value as MethodEnum]?.isSelected
                                    ? s.selected
                                    : ""
                            }  flex items-center mb-2 mr-2`}
                            key={uuidv4()}
                            onClick={() =>
                                onSelectPaymentProvider(paymentKey, value)
                            }
                        >
                            <div
                                className={`${s.paymentMethodIcon} flex items-center justify-center mr-2`}
                            >
                                <CreditIcon />
                            </div>

                            <div
                                className={`${s.paymentMethodContent} flex flex-col justify-start items-start gap-y-2`}
                            >
                                <h4
                                    className={`${s.paymentMethodName} caption-bold`}
                                >
                                    {t(`order.${value}`)}
                                </h4>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        );
    };

    const DisplayServicesProvider = () => {
        const keys = Object.keys(selectedPaymentMethod || {});

        if (!keys.length || !selectedPaymentMethod) {
            return <></>;
        }

        let provider: { data: any; paymentMethod: any } | null = null;

        loop1: for (const _key of keys) {
            for (const [_, value] of Object.entries(
                selectedPaymentMethod[_key as MethodEnum] || {},
            )) {
                if (value.isSelected && value.provider) {
                    provider = {
                        paymentMethod: _key,
                        data: value.provider,
                    };
                    break loop1;
                }
            }
        }

        if (!provider) {
            return <></>;
        }

        return (
            <section className={`${s.paymentContainer} payment-methods mt-2`}>
                <div className="flex mb-2">
                    <span className={`${s.methodTitle} caption-medium`}>
                        {t("order.services_provider")}
                    </span>
                </div>

                <section className={`${s.serviceProvidersRow} flex flex-wrap`}>
                    {provider?.data?.services?.map(
                        (service: IActiveMethodService, idx: number) => (
                            <DisplayServiceProvider
                                key={`${service.method}_${idx}`}
                                service={service}
                                paymentMethod={provider?.paymentMethod}
                                paymentProvider={provider?.data?.type}
                            />
                        ),
                    )}
                </section>
            </section>
        );
    };
    return (
        <>
            {!paymentMethodKeys.length ? (
                <p className={`${s.notEnoughConfig}`}>
                    {t("shop.not_enough_config")}
                </p>
            ) : (
                <>
                    <section
                        className={`${s.paymentContainer} payment-methods`}
                    >
                        <div className="w-full flex flex-col gap-y-2 gap-x-2 available-payment-methods">
                            {paymentMethodKeys.map((_key) => (
                                <DisplayPaymentMethod paymentMethod={_key} />
                            ))}
                        </div>
                    </section>

                    <section
                        className={`${s.paymentContainer} payment-methods mt-4`}
                    >
                        {paymentProviderKeys?.length ? (
                            <>
                                {paymentProviderKeys.map((key) => (
                                    <DisplayPaymentMethodProvider
                                        paymentKey={key}
                                    />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </section>

                    <DisplayServicesProvider />
                </>
            )}
        </>
    );
};
