import { DrawerCustom } from "@components/modules/custom-drawer";
import { cn } from "@utils/tailwind-marge";
import { Button, Divider, Image, Spin } from "antd";
import { ProductRatingItem } from "./product-rating-item";
import { useRatings } from "@services/api-hook/useRatings";
import {
    ProductRatingFilter,
    RatingFilterValue,
} from "./product-rating-filter";
import { useEffect, useState } from "react";
import {
    GetProductRatingsRequestDto,
    IItemRatingDto,
    IProductRatingSummary,
} from "@interfaces/market/ratings.interface";
import React from "react";

import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";

type Props = {
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => void;
    productId: string;
    summaryInfo: IProductRatingSummary;
};

export const ProductRatingDrawer = (props: Props) => {
    const { setIsOpen, isOpen, productId, summaryInfo } = props;

    const { t } = useTranslation("common");

    const [filter, setFilter] = useState<RatingFilterValue>({});

    const { fetchProductRatings } = useRatings();

    const [params, setParams] = useState<GetProductRatingsRequestDto>({
        productId,
        pageIndex: 1,
        pageSize: 20,
    });

    const {
        data: productRatingResponse,
        isLoading,
        error,
    } = fetchProductRatings({
        ...params,
        ...filter,
    });

    const [ratingItems, setRatingItems] = useState<IItemRatingDto[]>([]);

    const { data: productRatings, total: totalProductRatings } =
        productRatingResponse ?? {
            data: [],
            total: 0,
        };

    useEffect(() => {
        productRatings?.length &&
            setRatingItems([...ratingItems, ...productRatings]);
    }, [productRatings]);

    const hasNextPage =
        (productRatings?.length ?? 0) === (params.pageSize ?? 0);

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: () => {
            setParams({
                ...params,
                pageIndex: (params.pageIndex ?? 1) + 1,
            });
        },
        disabled: !!error,
        rootMargin: "0px 0px 400px 0px",
    });

    const handleSetFilter = ({ hasImage, star }: RatingFilterValue) => {
        setParams({
            ...params,
            pageIndex: 1,
            hasImage,
            star,
        });
        setFilter({ hasImage, star });
        setRatingItems([]);
    };

    return (
        <DrawerCustom
            closable={true}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            placement="bottom"
            key="productRating"
            title={
                <p className="text-lg font-semibold ml-2">
                    {t("rating.product_rating")}
                </p>
            }
            footer={
                <Button
                    onClick={() => setIsOpen(false)}
                    className={cn(
                        "h-10 rounded-full flex justify-center items-center w-full bg-primary border-none outline-none",
                        "font-sans font-semibold text-base !text-neutral-0",
                    )}
                >
                    {t("common.back")}
                </Button>
            }
            closeIcon={
                <Image
                    alt="close"
                    src="/icons/icons8-left.svg"
                    preview={false}
                />
            }
        >
            <div className="flex flex-col gap-2">
                <ProductRatingFilter
                    filter={filter}
                    setFilter={handleSetFilter}
                    summaryInfo={summaryInfo}
                />

                <article ref={rootRef} className="flex flex-col gap-4">
                    {ratingItems?.map((item) => (
                        <React.Fragment key={item.id}>
                            <ProductRatingItem item={item} />
                            <Divider className="m-0" />
                        </React.Fragment>
                    ))}
                    {(isLoading || hasNextPage) && (
                        <div className="flex justify-center" ref={sentryRef}>
                            <Spin spinning />
                        </div>
                    )}
                </article>
            </div>
        </DrawerCustom>
    );
};
